import { useAuth, useClerk } from '@clerk/clerk-react'
import { Box, GlobalStyles, Grid2, useMediaQuery } from '@mui/material'
import { parse } from 'date-fns'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { ConnectivityToast } from 'src/components/ConnectivityToast/ConnectivityToast'
import useFreshChatWidget from 'src/hooks/useFreshChatWidget'
import {
  desktopMenuHandIcon,
  desktopMenuHandIconActive,
  desktopMenuShieldIcon,
  desktopMenuShieldIconActive,
  loanIncreaseIcon,
  paymentProtectionIcon,
} from 'src/images'
import { segmentAnalytics } from 'src/portal/analytics'
import { LoanStatusEnum, PortalRoutesEnum } from 'src/portal/common'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import Header from 'src/portal/layout/header/Header'
import { MenuButtonItem } from 'src/portal/layout/menu/common'
import DesktopMenu from 'src/portal/layout/menu/desktop/DesktopMenu'
import MobileMenu from 'src/portal/layout/menu/mobile/MobileMenu'
import PortalTheme from 'src/themes/portal'
import { getDeviceType } from 'src/utils'
import {
  EnvEnum,
  ENVIRONMENT,
  FRESHCHAT_HOST,
  FRESHCHAT_SCRIPT_URL,
  FRESHCHAT_TOKEN,
  FRESHCHAT_WIDGET_UUID,
} from 'src/utils/constants'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  const queryClient = useQueryClient()
  const isMobile = useMediaQuery(PortalTheme.breakpoints.down('sm'))
  const { signOut } = useClerk()
  const { isSignedIn } = useAuth()
  const { loanDetailData, borrowerDetailData } = usePortalContext()
  const { t } = useTranslation()
  const { isPaymentProtectionEnabled } = usePortalContext()

  const isEligibleForCreditRenewal = (() => {
    if (
      !loanDetailData?.loan_disbursal_date ||
      loanDetailData?.loan_status === LoanStatusEnum.CLOSED_REFINANCED
    ) {
      return false
    }

    const disbursalDate = parse(loanDetailData.loan_disbursal_date, 'yyyy-MM-dd', new Date())
    const today = parse(loanDetailData.system_date, 'yyyy-MM-dd', new Date())

    // Calculate the difference in days
    const timeDifference = today.getTime() - disbursalDate.getTime()
    const MILLISECONDS_IN_A_SECOND = 1000
    const SECONDS_IN_A_MINUTE = 60
    const MINUTES_IN_AN_HOUR = 60
    const HOURS_IN_A_DAY = 24
    const MILLISECONDS_IN_A_DAY =
      MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY
    const daysDifference = Math.floor(timeDifference / MILLISECONDS_IN_A_DAY)
    // Check if within 30 days (including day 0)

    const hasLoanBeenDisbursedWithinThirtyDays = daysDifference >= 0 && daysDifference <= 30
    const isLoanRefinanced = loanDetailData.loan_contract_type === 'Refinance'

    // Credit renewal should be shown to users who have booked their loan within 30 days of system date
    // and
    // the loan is not a refinanced loan

    return hasLoanBeenDisbursedWithinThirtyDays && !isLoanRefinanced
  })()

  useFreshChatWidget({
    scriptSrc: FRESHCHAT_SCRIPT_URL,
    shouldShow: true,
    freshChatCustomParams: {
      cf_environment: ENVIRONMENT === EnvEnum.local ? 'development' : ENVIRONMENT,
    },
    freshChatInitParams: {
      token: FRESHCHAT_TOKEN,
      externalId: loanDetailData?.borrower_id,
      host: FRESHCHAT_HOST,
      widgetUuid: FRESHCHAT_WIDGET_UUID,
    },
    freshChatContactParams: {
      email: borrowerDetailData?.email ?? '',
      phone: borrowerDetailData?.phone ?? '',
      firstName: borrowerDetailData?.first_name ?? '',
      lastName: borrowerDetailData?.last_name ?? '',
    },
  })

  useEffect(() => {
    if (loanDetailData?.borrower_id && loanDetailData?.application_id) {
      segmentAnalytics.identify(loanDetailData?.borrower_id, {
        user_id: loanDetailData?.borrower_id,
        application_id: loanDetailData?.application_id,
        user_agent: navigator.userAgent,
        device_type: getDeviceType(),
      })
    }
  }, [loanDetailData?.borrower_id, loanDetailData?.application_id])

  const mobileOptionalItemList: MenuButtonItem[] = [
    {
      icon: paymentProtectionIcon,
      label: t('Portal.Menu.label.paymentProtection'),
      iconAlt: t('Portal.Menu.alt.paymentProtectionIcon'),
      route: PortalRoutesEnum.PORTAL_PAYMENT_PROTECTION,
      isHidden: !isPaymentProtectionEnabled,
    },
    {
      icon: loanIncreaseIcon,
      label: t('Portal.Menu.label.loanIncrease'),
      iconAlt: t('Portal.Menu.alt.loanIncreaseIcon'),
      route: PortalRoutesEnum.PORTAL_LOAN_INCREASE,
      isHidden: !isEligibleForCreditRenewal,
    },
  ]

  const desktopOptionalItemList = [
    {
      icon: desktopMenuShieldIcon,
      iconActive: desktopMenuShieldIconActive,
      iconAlt: t('Portal.Menu.alt.paymentProtectionIcon'),
      label: t('Portal.Menu.label.paymentProtection'),
      isHidden: !isPaymentProtectionEnabled,
      route: PortalRoutesEnum.PORTAL_PAYMENT_PROTECTION,
    },
    {
      icon: desktopMenuHandIcon,
      iconActive: desktopMenuHandIconActive,
      iconAlt: t('Portal.Menu.alt.loanIncreaseIcon'),
      label: t('Portal.Menu.label.loanIncrease'),
      isHidden: !isEligibleForCreditRenewal,
      route: PortalRoutesEnum.PORTAL_LOAN_INCREASE,
    },
  ]

  return (
    <Box sx={{ backgroundColor: PortalTheme.color.grey1, height: '100%' }}>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: PortalTheme.color.grey1,
          },
          //  style the freshchat widget for portal layout
          [PortalTheme.breakpoints.up('md')]: {
            '.fc-widget-normal': { bottom: '48px !important', right: '48px !important' },
          },
          [PortalTheme.breakpoints.down('md')]: {
            '.fc-widget-normal': { bottom: '120px !important', right: '24px !important' },
          },
        }}
      />
      <Header
        signOut={() => {
          queryClient.clear() // Clear the cache on logout for the next session
          signOut({ redirectUrl: '/portal' })
        }}
        isSignedIn={isSignedIn}
      />
      <Grid2 container sx={isMobile ? { flex: 1, overflowY: 'auto', paddingBottom: '100px' } : {}}>
        {isMobile ? (
          <MobileMenu optionalItemList={mobileOptionalItemList} />
        ) : (
          <Grid2 size={{ sm: 3, md: 2 }}>
            <DesktopMenu optionalItemList={desktopOptionalItemList} />
          </Grid2>
        )}
        <Grid2 size={{ xs: 12, sm: 9, md: 10 }} p={isMobile ? '24px' : '48px'}>
          <ConnectivityToast />
          {children}
        </Grid2>
      </Grid2>
    </Box>
  )
}
