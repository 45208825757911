import { captureMessage } from '@sentry/react'
import { MutationObserverOptions, QueryClient, QueryObserverOptions } from 'react-query'

import { StepsEnum } from 'src/types'
import { captureExceptionHelper, getAxiosErrorValues } from 'src/utils'

enum StatusEnum {
  ERROR = 'ERROR',
  DECLINED = 'DECLINED',
}

const always = 'always' as const
const defaultQueryConfig = {
  staleTime: Infinity,
  cacheTime: Infinity,
  retry: false,
  refetchOnMount: always,
  refetchOnWindowFocus: false,
  useErrorBoundary: true,
}
const defaultMutateConfig = {
  retry: false,
  useErrorBoundary: true,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: defaultQueryConfig,
    mutations: defaultMutateConfig,
  },
})

export const retryQueryOptions = {
  retry: (failureCount: number, error: any) => {
    // If we have already retried 3 times, then return false to stop retrying
    if (failureCount === 3) {
      return false
    }

    // if we get the Declined statue, then stop retrying
    // getRetryQueryOptionsWithErrorStep will redirect user to Declined page
    if (error.response?.data?.status === StatusEnum.DECLINED) {
      return false
    }

    const axiosValues = getAxiosErrorValues(error)
    if (axiosValues?.isConnectionError) {
      captureMessage('Axios Network Error Retry', {
        extra: axiosValues,
        level: 'warning',
      })
    }

    // Return true to retry until we hit # of retries above.
    return true
  },

  /**
   * Constant backoff retry function for queries
   * @param attempt - The current attempt number
   * @param error - The error object from the previous attempt
   * @returns The delay in milliseconds
   * @throws If the maximum number of retries has been exceeded
   */
  retryDelay: (attempt: number, error: any) => {
    if (attempt > 3) {
      throw error
    }
    return attempt * 2000 // milliseconds
  },
}

/**
 * Helper function to get the retry query options with error handling step to log errors and navigate to error step
 * @param queryName - The name of the query - used for logging
 * @param setGenericErrorPageError - Passing error to Generic Error page to better view why we hit the Generic Error Page
 * @param setStep - setStep function from origination context for navigating to the error step, omit if navigation not required
 */
export const getRetryQueryOptionsWithErrorStep = ({
  queryName,
  setGenericErrorPageError,
  setStep,
}: {
  queryName: string
  setGenericErrorPageError?: (error: any) => void
  setStep?: (step: string) => void
}): Pick<
  QueryObserverOptions & MutationObserverOptions,
  'retry' | 'retryDelay' | 'useErrorBoundary' | 'onError'
> => ({
  ...retryQueryOptions,
  useErrorBoundary: false,
  onError: (error: any) => {
    captureExceptionHelper(`Query failed after retries: ${queryName}`, error)
    setGenericErrorPageError?.(new Error(`Query failed after retries: ${queryName}`))

    const nextStep =
      error.response?.data?.status === StatusEnum.DECLINED ? StepsEnum.DECLINED : StepsEnum.ERROR
    setStep?.(nextStep)
  },
})

export default queryClient
