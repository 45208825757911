import { List, ListItem, ListItemButton, ListItemText, styled } from '@mui/material'

import theme from 'src/themes'

export const ScheduleSelectListItemText = styled(ListItemText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  fontWeight: 500,
  justifyContent: 'space-between',
  '& .MuiListItemText-primary': {
    color: theme.color.grey8,
  },
  '& .MuiListItemText-secondary': {
    color: theme.color.grey6,
    fontSize: '18px',
    marginLeft: '5px',
    fontWeight: 400,
  },
}))

export const ScheduleSelectListItem = styled(ListItem)(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: '8px',
  },
  ':hover': {
    borderColor: theme.color.brand1,
    background: 'none',
  },
}))

export const ScheduleSelectListItemButton = styled(ListItemButton)({
  paddingTop: '18px',
  paddingBottom: '18px',
  paddingLeft: '24px',
  justifyContent: 'left',

  borderColor: theme.color.grey3,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  boxSizing: 'border-box',
  ':hover': {
    borderColor: theme.color.brand1,
    background: 'white',
  },
  '&.Mui-selected': {
    borderColor: theme.color.brand1,
    borderWidth: '1.5px',
    background: 'white',
  },
  '&.Mui-selected:hover': {
    background: 'white',
  },
})

export const RepaymentScheduleList = ({
  options,
}: {
  options: {
    onClick: () => void
    isSelected: boolean
    primaryText: string
    secondaryText?: string
  }[]
}) => {
  return (
    <List>
      {options.map(
        ({ onClick, isSelected: selected, primaryText: primary, secondaryText: secondary }) => (
          <ScheduleSelectListItem key={primary + (secondary ?? '')} disablePadding>
            <ScheduleSelectListItemButton disableRipple {...{ onClick, selected }}>
              <ScheduleSelectListItemText {...{ primary, secondary }} />
            </ScheduleSelectListItemButton>
          </ScheduleSelectListItem>
        ),
      )}
    </List>
  )
}
