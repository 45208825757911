export enum ConnectionStepEnum {
  DECLINED = 'declined',
  PAYMENT_METHOD_SELECT = 'paymentmethod',
  BANK_AUTO_CONNECT = 'autoconnect',
  BANK_CONNECT_ERROR = 'error',
  BANK_SUCCESS = 'success',
  FLINKS_PAYMENT_CHECK = 'bank/flinkscheck',
  CONFIRM_NEW_PAYMENT_METHOD = 'confirmnewpaymentmethod',
  ACCOUNT_ALREADY_LINKED = 'accountalreadylinked',
}

export enum LinkBankAccountStatusEnum {
  SUCCESS = 'success',
  ALREADY_LINKED = 'already_linked',
}
