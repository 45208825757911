import { LoanQueryResponseSchema } from 'src/portal/api/api.schemas'
import { LoanStatusEnum, PortalModalsEnum } from 'src/portal/common'
import useModalContext from 'src/portal/hooks/useModalContext'
import { PayNowButton } from 'src/portal/pages/home/components/PayNowButton'
import { AutomaticPaymentsContainer } from 'src/portal/pages/home/summary/containers/AutomaticPaymentsContainer'
import { PaymentProtectionContainer } from 'src/portal/pages/home/summary/containers/PaymentProtectionContainer'
import { UpcomingPaymentAmountContainer } from 'src/portal/pages/home/summary/containers/UpcomingPaymentAmountContainer'
import { UpcomingPaymentDateContainer } from 'src/portal/pages/home/summary/containers/UpcomingPaymentDateContainer'

export const PaymentDetailsContainer = ({
  loanDetailData,
}: {
  loanDetailData: LoanQueryResponseSchema
}) => {
  const { openModal } = useModalContext()

  const upcomingPaymentAmount = loanDetailData.due_amount
  const upcomingPaymentDate = loanDetailData.due_date
  const paymentProtectionActive = loanDetailData.payment_protection_applied!
  const automaticPaymentsOn = !loanDetailData.manual_pay
  const shouldShowPayNowButton =
    !automaticPaymentsOn && loanDetailData.loan_status === LoanStatusEnum.GOOD // note: pay now button will be shown in the OutstandingPaymentContainer if loan is overdue

  return (
    <>
      <UpcomingPaymentAmountContainer upcomingPaymentAmount={upcomingPaymentAmount} />
      <UpcomingPaymentDateContainer upcomingPaymentDate={upcomingPaymentDate} />
      <AutomaticPaymentsContainer
        automaticPaymentsOn={automaticPaymentsOn}
        viewDetailsClicked={() => openModal(PortalModalsEnum.AUTOMATIC_PAYMENTS_DETAIL)}
      />
      <PaymentProtectionContainer paymentProtectionActive={paymentProtectionActive} />

      {shouldShowPayNowButton && (
        <PayNowButton
          onClickHandler={() => {
            openModal(PortalModalsEnum.MANUAL_PAYMENT_MODAL)
          }}
        />
      )}
    </>
  )
}
