import { Grid2 } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { SectionHeading } from 'src/portal/pages/home/components/SectionHeading'
import { TransactionsRow } from 'src/portal/pages/home/components/TransactionsRow'

export const Transactions = () => {
  const { loanTransactionHistoryData, portalApiGetLoanTransactionHistoryRefetch, loanDetailData } =
    usePortalContext()

  const { t } = useTranslation()

  useEffect(() => {
    portalApiGetLoanTransactionHistoryRefetch()
  }, [])

  if (!loanTransactionHistoryData || !loanDetailData) {
    return (
      <div>
        <LoadingAnimation />
      </div>
    )
  }

  const paymentProtectionApplied = loanDetailData.payment_protection_applied

  return (
    <Grid2 container flexDirection="column">
      <SectionHeading
        title={t('Portal.Home.label.transactions.title')}
        description={t('Portal.Home.label.transactions.description')}
      />
      {loanTransactionHistoryData.map(transaction => (
        <TransactionsRow key={transaction.id} {...{ transaction, paymentProtectionApplied }} />
      ))}
    </Grid2>
  )
}
