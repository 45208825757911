import { Checkbox, FormControlLabel, Grid2, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { PaymentBreakdownResponseSchema } from 'src/portal/api/api.schemas'
import { PortalModalsEnum } from 'src/portal/common'
import { InfoMessage } from 'src/portal/components/InfoMessage'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import { PaymentBreakdownTable } from 'src/portal/components/modals/ManualPayments/PaymentBreakdownTable'
import { PortalLink } from 'src/portal/components/PortalLink'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import PortalTheme from 'src/themes/portal'
import { captureExceptionHelper } from 'src/utils'

const ConfirmPaymentModalContent = ({
  setNextButtonEnabled,
}: {
  setNextButtonEnabled: (value: boolean) => void
}) => {
  const { t } = useTranslation()
  const { loanDetailData, paymentDetailData } = usePortalContext()
  const { paymentAmount, paymentType } = useModalContext()
  const { portalApiGetPaymentBreakdown } = usePortalApi()
  const { currencyFormat, dateFormatLocal } = useLocalizedFormatters()

  const { isLoading: paymentBreakdownLoading, data: paymentBreakdownData } =
    useQuery<PaymentBreakdownResponseSchema>(
      ['portalApiGetPaymentBreakdown', loanDetailData?.id, paymentAmount],
      async () => {
        if (loanDetailData?.id) {
          return portalApiGetPaymentBreakdown(loanDetailData?.id, {
            payment_amount: paymentAmount,
            payment_type: paymentType,
          })
        }
        return Promise.reject('No application ID')
      },
      {
        enabled: !!(loanDetailData?.id && paymentAmount),
        useErrorBoundary: false,
      },
    )

  const [paymentBreakdownOpen, setPaymentBreakdownOpen] = useState(false)
  const [consentCheckboxChecked, setConsentCheckboxChecked] = useState(false)
  const paymentAccount = paymentDetailData?.[0]?.payment_method_details

  const paymentBreakdownTableProps = {
    principalAmount: paymentBreakdownData?.principal_amount || 0,
    creditChargeAmount: paymentBreakdownData?.interest_amount || 0,
    paymentProtectionAmount: paymentBreakdownData?.payment_protection_amount || 0,
    feesAmount: paymentBreakdownData?.nsf_fees || 0,
    totalAmount: paymentBreakdownData?.total_amount || 0,
  }

  const onClickHandler = () => {
    setPaymentBreakdownOpen(!paymentBreakdownOpen)
  }

  const checkBoxHandler = () => {
    setNextButtonEnabled(true)
    setConsentCheckboxChecked(!consentCheckboxChecked)
  }
  if (!loanDetailData || !paymentDetailData || paymentBreakdownLoading) {
    return <LoadingAnimation />
  }
  return (
    <>
      <SpacedRow>
        <Typography variant="body2" color={PortalTheme.color.grey10} fontWeight={500}>
          {t('Portal.Components.modal.confirmPayment.label.paymentAmount')}
        </Typography>
        <Typography variant="body2" color={PortalTheme.color.grey6}>
          {currencyFormat(paymentAmount)}
        </Typography>
      </SpacedRow>

      <PortalLink
        text={t('Portal.Components.modal.confirmPayment.label.viewPaymentBreakdown')}
        onClickHandler={onClickHandler}
        iconDirection={paymentBreakdownOpen ? 'up' : 'down'}
      />

      {paymentBreakdownOpen && <PaymentBreakdownTable {...paymentBreakdownTableProps} />}

      <StretchDivider />

      <Typography variant="body2" color={PortalTheme.color.grey10} fontWeight={500}>
        {t('Portal.Components.modal.confirmPayment.label.paymentDate')}
      </Typography>

      <SpacedRow>
        <Grid2 size={7}>
          <Typography variant="body3" color={PortalTheme.color.grey6}>
            {t('Portal.Components.modal.confirmPayment.label.transactionTime')}
          </Typography>
        </Grid2>
        <Grid2 size={5}>
          <Typography variant="body2" color={PortalTheme.color.grey6} textAlign={'right'}>
            {dateFormatLocal(loanDetailData.system_date)}
          </Typography>
        </Grid2>
      </SpacedRow>

      <StretchDivider />

      <SpacedRow>
        <Grid2 size={6}>
          <Typography variant="body2" color={PortalTheme.color.grey10} fontWeight={500}>
            {t('Portal.Components.modal.confirmPayment.label.paymentAccount')}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography variant="body2" color={PortalTheme.color.grey6} textAlign={'right'}>
            {paymentAccount}
          </Typography>
        </Grid2>
      </SpacedRow>

      <StretchDivider />

      <InfoMessage content={t('Portal.Components.modal.confirmPayment.label.infoMessage')} />

      <FormControlLabel
        control={<Checkbox checked={consentCheckboxChecked} onChange={checkBoxHandler} />}
        label={
          <Typography pt="16px" variant="body2" color={PortalTheme.color.grey6} display={'inline'}>
            {t('Portal.Components.modal.confirmPayment.label.consentCheckbox')}
          </Typography>
        }
      />
    </>
  )
}

export const ConfirmPaymentModal = () => {
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const { closeModal, closeAllModals, openModal, paymentAmount, paymentType } = useModalContext()
  const { portalApiMakePayment } = usePortalApi()
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false)
  const { mutate: submitPayment, isLoading } = useMutation(
    ({
      loanId,
      paymentAmount,
      paymentType,
    }: {
      loanId: string
      paymentAmount: number
      paymentType: string
    }) => portalApiMakePayment(loanId, paymentAmount, paymentType),
    {
      onSuccess: () => {
        openModal(PortalModalsEnum.PAYMENT_SUCCESS_MODAL)
      },
      onError: (error: any) => {
        captureExceptionHelper('Error submitting payment', error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
      },
    },
  )
  const handlePaymentSubmit = () => {
    submitPayment({
      loanId: loanDetailData?.id || '',
      paymentAmount,
      paymentType,
    })
  }

  return (
    <ActionModal
      title={t('Portal.Components.modal.confirmPayment.title')}
      content={
        isLoading ? (
          <LoadingAnimation />
        ) : (
          <ConfirmPaymentModalContent setNextButtonEnabled={setNextButtonEnabled} />
        )
      }
      buttonText={t('Portal.Components.modal.confirmPayment.buttonText')}
      closeButtonHandler={closeAllModals}
      backButtonHandler={closeModal}
      nextButtonHandler={handlePaymentSubmit}
      nextButtonEnabled={nextButtonEnabled && !isLoading}
    />
  )
}
