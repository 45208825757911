import { SignedIn, SignedOut } from '@clerk/clerk-react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Outlet, Route, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'

import { GenericError } from 'src/components/Error/GenericError'
import MaintenanceMode from 'src/components/Error/MaintenanceMode'
import PageNotFound from 'src/components/Error/PageNotFound'
import RootLayout from 'src/components/common/RootLayout'
import { logoImgSvg } from 'src/images'
import { PortalRoutesEnum } from 'src/portal/common'
import PortalIndex from 'src/portal/index'
import Layout from 'src/portal/layout'
import PortalPaymentProtection from 'src/portal/pages/paymentProtection/PaymentProtection'
import PortalProfileIndex from 'src/portal/profile/index'
import PortalDocumentsIndex from 'src/portal/pages/documents/index'
import PortalCreditRenewalIndex from 'src/portal/pages/creditRenewal/index'
import PortalPaymentIndex from 'src/portal/pages/payments/Payments'
import FlinksChangeBankAccount from 'src/portal/pages/flinks/index'
import { SentryRoutes } from 'src/routers/common'
import { FeatureFlags } from 'src/types'
import SignInPage from 'src/portal/pages/auth/signinClerk'

const PortalRouteWrapper = () => {
  const { isMaintenance } = useFlags<FeatureFlags>()

  if (isMaintenance) {
    return (
      <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
        <MaintenanceMode />
      </RootLayout>
    )
  }

  return (
    <>
      <SignedOut>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <SignInPage />
        </Box>
      </SignedOut>
      <SignedIn>
        <Layout>
          <Outlet />
        </Layout>
      </SignedIn>
    </>
  )
}

export default function PortalRouter() {
  const location = useLocation()

  return (
    <SentryRoutes>
      <Route element={<PortalRouteWrapper />}>
        <Route index element={<PortalIndex />} />
        <Route path={PortalRoutesEnum.PORTAL_PROFILE} element={<PortalProfileIndex />} />
        <Route path={PortalRoutesEnum.PORTAL_DOCUMENTS} element={<PortalDocumentsIndex />} />
        <Route
          path={PortalRoutesEnum.PORTAL_PAYMENT_PROTECTION}
          element={<PortalPaymentProtection />}
        />
        <Route
          path={PortalRoutesEnum.PORTAL_CHANGE_BANK_ACCOUNT}
          element={<FlinksChangeBankAccount />}
        />
        <Route path={PortalRoutesEnum.PORTAL_PAYMENTS} element={<PortalPaymentIndex />} />
        <Route
          path={PortalRoutesEnum.PORTAL_LOAN_INCREASE}
          element={<PortalCreditRenewalIndex />}
        />

        <Route
          path={PortalRoutesEnum.PORTAL_ERROR}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <GenericError isUncaughtError={false} />
            </RootLayout>
          }
        />
        <Route
          path="*"
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <PageNotFound currentLocation={location.pathname} />
            </RootLayout>
          }
        />
      </Route>
    </SentryRoutes>
  )
}
