import { Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { PortalLink } from 'src/portal/components/PortalLink'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import PortalTheme from 'src/themes/portal'
import { pdfFileDownload } from 'src/utils/files'

interface DocumentListProps {
  documentDate: string
  documentData: string
}

export const DocumentList = ({ documentDate, documentData }: DocumentListProps) => {
  const { t } = useTranslation()
  const { dateFormatLocal } = useLocalizedFormatters()
  const documentName = t('Portal.PaymentProtection.documentName')
  return (
    <>
      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey7}>
            {dateFormatLocal(documentDate)}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body2" color={PortalTheme.color.grey10}>
            {t('Portal.PaymentProtection.certificateOfInsurance')}
          </Typography>
        </Grid2>
        <Grid2 alignItems="flex-start">
          <PortalLink
            text={t('Portal.PaymentProtection.download')}
            onClickHandler={() => pdfFileDownload(documentData, `${documentName}.pdf`)}
            iconDirection="right"
          />
        </Grid2>
      </SpacedRow>
    </>
  )
}
