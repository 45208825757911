import { parse } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from 'src/locales'
import { BankingType } from 'src/types'
import {
  currencyFormatFromCents as currencyFormatFromCentsWithLanguage,
  currencyFormatFromStr as currencyFormatFromStrWithLanguage,
  currencyFormat as currencyFormatWithLanguage,
  dateFormatCustom as dateFormatCustomWithLanguage,
  dateISOFormat,
  percentFormat as percentFormatWithLanguage,
} from 'src/utils/format'
import { captureExceptionHelper } from 'src/utils'

/*
 * make sure this does not include OriginationContext / PrequalificationContext logic,
 * this used in both origination and e2e
 */
export const useLocalizedFormatters = () => {
  const { t, i18n } = useTranslation()
  const language = i18n.resolvedLanguage as SupportedLanguage

  return {
    dateFormat: dateISOFormat,

    dateFormatCustom: (date: Date | null, formatStr: string) =>
      dateFormatCustomWithLanguage({ date, formatStr, language }),

    dateFormatLocal: (dateStr: string) => {
      try {
        return dateFormatCustomWithLanguage({
          date: parse(dateStr, 'yyyy-MM-dd', new Date()),
          formatStr: t('Portal.common.dateFormat'),
          language,
        })
      } catch (error) {
        captureExceptionHelper('Error in dateFormatLocal', error)
        return dateStr
      }
    },

    currencyFormatFromCents: (amountInCents: number | undefined, minFractionDigits = 2) =>
      currencyFormatFromCentsWithLanguage(amountInCents, minFractionDigits, language),

    currencyFormatFromStr: (amount: string | undefined, minFractionDigits = 2) =>
      currencyFormatFromStrWithLanguage(amount, minFractionDigits, language),

    currencyFormat: (amount: number | undefined, minFractionDigits = 2) =>
      currencyFormatWithLanguage(amount, minFractionDigits, language),

    percentFormat: (amount: number | undefined, minFractionDigits = 2) =>
      percentFormatWithLanguage(amount, minFractionDigits, language),

    bankFormat: (bank: BankingType) =>
      `${bank.institution_name} ${t(`common.${bank.account_type.toLowerCase()}`, '')} ${
        bank.account_number
      }`,
  }
}
