import { AnalyticsBrowser } from '@segment/analytics-next'
import * as Sentry from '@sentry/react'

import { PortalRoutesEnum } from 'src/portal/common'
import { ENVIRONMENT, SSP_SEGMENT_WRITE_KEY } from 'src/utils/constants'
import log from 'src/utils/log'

interface SegmentAnalytics {
  identify: (event: any, params: any) => void
  track: (event: any, params: any) => void
  page: (params?: any) => void
  group: () => void
}

const loadSegment = (): SegmentAnalytics => {
  // If for some reason segment isn't loading don't crash the app
  const isSSP = window.location.pathname.includes('/portal')
  try {
    if (ENVIRONMENT && SSP_SEGMENT_WRITE_KEY && isSSP) {
      return AnalyticsBrowser.load({ writeKey: SSP_SEGMENT_WRITE_KEY })
    }
  } catch (e) {
    Sentry.captureMessage(`Segment did not load due to ${e}`, 'warning')
  }

  return {
    identify: (event: any, params: any) => log.info('segment', 'identify', event, params),
    track: (event: any, params: any) => log.info('segment', 'track', event, params),
    page: (properties: any) => log.info('segment', 'page', properties),
    group: () => log.info('segment', 'group'),
  }
}

// This object is only set on local testing, staging, preprod and production
export const segmentAnalytics: SegmentAnalytics = loadSegment()

export enum SEGMENT_CONSTANTS {}
//   TODO: write for each page

export const getAnalyticsCallback =
  (segmentName: string) =>
  (object: string, page_name: PortalRoutesEnum, action = 'Clicked', extra?: Record<string, any>) =>
    segmentAnalytics.track(segmentName, {
      object,
      page_name,
      action,
      ...extra,
    })
