import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { Box, Grid2, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { ButtonCTA } from 'src/components/common/Buttons'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { warningImg } from 'src/images'
import { PortalModalsEnum } from 'src/portal/common'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { ContentColumn, FigAlertError } from 'src/portal/pages/reschedule/components/common'
import { DivRoot } from 'src/portal/pages/reschedule/styles'
import theme from 'src/themes'
import { captureExceptionHelper } from 'src/utils'

const CustomImage = styled('img')({
  width: '80px',
  height: '80px',
})

const GridImage = styled(Grid2)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '32px',
})

const CustomImageGrid = styled(GridImage)({
  marginBottom: '32px',
})

const CustomTitle = styled(Typography)({
  ...theme.typography.h1,
  textAlign: 'center',
  marginBottom: '16px',
  whiteSpace: 'pre-line',
})

const CustomButtonGrid = styled(Grid2)({
  gap: '18px',
  display: 'flex',
  flexDirection: 'column',
})

const ScheduleHeader = styled(Grid2)(({ theme }) => ({
  borderColor: theme.color.grey3,
  padding: '10px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderBottom: 'none',
  borderRadius: '4px',
  borderBottomWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.color.brand5,
}))

const ScheduleContainer = styled(Grid2)(({ theme }) => ({
  borderColor: theme.color.grey3,
  padding: '5px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderTop: 'none',
  borderRadius: '4px',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  width: '100%',
  marginBottom: '32px',
  paddingTop: '16px',
  paddingBottom: '16px',
}))

interface SectionContainerProps {
  buttonText: string
  headerTitle: string
  sectionContent: JSX.Element
  buttonVariant: 'contained' | 'outlined'
  onClick: VoidFunction
}

const SectionContainer = ({
  sectionContent,
  headerTitle,
  buttonText,
  buttonVariant,
  onClick,
}: SectionContainerProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ScheduleHeader size={12}>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CalendarMonthOutlinedIcon
            sx={{ color: theme.color.grey9 }}
            aria-label={t('PaymentSchedule.PaymentScheduleCalendar.alt')}
          />
          <Typography
            sx={{
              color: theme.color.black,
              paddingLeft: '5px',
              fontWeight: 500,
            }}
          >
            {headerTitle}
          </Typography>
        </Box>
      </ScheduleHeader>
      <ScheduleContainer>{sectionContent}</ScheduleContainer>
      <CustomButtonGrid size={12}>
        <ButtonCTA variant={buttonVariant} onClick={onClick} buttonText={buttonText} />
      </CustomButtonGrid>
    </>
  )
}
export default function SelectPage({ onClick }: { onClick?: VoidFunction }) {
  const { t } = useTranslation()
  const {
    portalApiPaymentScheduleChange: { validateMutation },
    loanDetailData,
  } = usePortalContext()
  const { openModal } = useModalContext()
  const { currencyFormat, dateFormatLocal } = useLocalizedFormatters()
  const { portalApiPaymentDeferralValidateSchedule } = usePortalApi()

  const { isLoading: isValidatingDeferral, data: validateScheduleResponse } = useQuery(
    ['validateDeferralDate'],
    async () => {
      return portalApiPaymentDeferralValidateSchedule(loanDetailData?.id || '')
    },
    {
      enabled: !!loanDetailData?.id, // run the query on component mount
      useErrorBoundary: false,
      onError: (error: any) => {
        captureExceptionHelper(`Error delaying next payment: ${error}`, error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
      },
    },
  )

  if (isValidatingDeferral || !validateScheduleResponse) {
    return <LoadingAnimation />
  }

  const currentBillingDate = validateMutation.data?.current_payment_date
    ? dateFormatLocal(validateMutation.data?.current_payment_date || '')
    : ''

  return (
    <DivRoot>
      <Grid2 container>
        <Grid2 size={12}>
          <CustomImageGrid size={12}>
            <CustomImage src={warningImg} alt={t('Portal.Reschedule.title')} />
          </CustomImageGrid>

          <Grid2 size={12}>
            <CustomTitle data-testid="errorTitle">{t('Portal.Reschedule.title')}</CustomTitle>
          </Grid2>

          <Grid2 size={12} marginTop="16px" marginBottom="32px">
            <Typography textAlign="center" variant="body2">
              {t('Portal.Reschedule.change.sectionTitle', {
                currentDate: currentBillingDate,
              })}
            </Typography>
          </Grid2>

          <SectionContainer
            headerTitle={t('Portal.Reschedule.change.headerTitle')}
            buttonText={t('Portal.Reschedule.change.buttonText')}
            sectionContent={
              <>
                <ContentColumn
                  left={t('Portal.Reschedule.change.sectionContent')}
                  right={currentBillingDate || ''}
                />
              </>
            }
            buttonVariant="contained"
            onClick={() => onClick?.()}
          />

          {!!validateMutation.data?.current_payment_cycle_remainings?.length && (
            <>
              <Grid2 size={12} my="24px" />

              <Grid2 size={12}>
                <Typography textAlign="left" variant="body2" marginTop="32px" marginBottom="16px">
                  {t('Portal.Reschedule.delay.sectionTitle')}
                </Typography>
              </Grid2>

              <SectionContainer
                headerTitle={t('Portal.Reschedule.delay.headerTitle')}
                buttonText={t('Portal.Reschedule.delay.buttonText')}
                sectionContent={
                  <>
                    {validateMutation.data?.current_payment_cycle_remainings?.map(x => (
                      <ContentColumn
                        key={x.date}
                        left={currencyFormat(Number(x.amount || 0))}
                        right={dateFormatLocal(x.date || '')}
                      />
                    ))}

                    <ContentColumn
                      left={t('Portal.Reschedule.delay.sectionContent')}
                      right={dateFormatLocal(validateMutation.data?.current_bill_date || '')}
                    />
                  </>
                }
                buttonVariant="outlined"
                onClick={() => openModal(PortalModalsEnum.DELAY_NEXT_PAYMENT_MODAL)}
              />

              <Grid2 size={12}>
                <Typography textAlign="left" variant="body2" marginTop="16px">
                  {t('Portal.Reschedule.delay.footerText')}
                </Typography>
              </Grid2>
            </>
          )}
        </Grid2>
      </Grid2>
    </DivRoot>
  )
}

export const ChangePaymentIndexModal = () => {
  const { openModal, closeAllModals } = useModalContext()
  const {
    portalApiPaymentScheduleChange: { validateMutation },
  } = usePortalContext()

  const handleContinue = async () => {
    try {
      // Clears all internal states including errors in order to try again
      validateMutation.reset()
      await validateMutation.mutateAsync({})
      openModal(PortalModalsEnum.CHANGE_PAYMENT_RESCHEDULE_EDIT)
    } catch (error) {
      captureExceptionHelper('Error continuing from Portal.ConfirmSchedule', error)
    }
  }

  return (
    <ActionModal
      content={
        Object.keys(validateMutation.data || {}).length === 0 ? (
          <LoadingAnimation />
        ) : (
          <>
            {validateMutation.isError && <FigAlertError />}
            <SelectPage onClick={handleContinue} />
          </>
        )
      }
      closeButtonHandler={closeAllModals}
    />
  )
}
