import { eachDayOfInterval, format, parse } from 'date-fns'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { FigDatePickerDay, getIsInvalidDateFromRange } from 'src/components/common/FigDatePicker'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { PortalModalsEnum } from 'src/portal/common'
import { InfoMessage } from 'src/portal/components/InfoMessage'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { PaymentDeferralContent } from 'src/portal/pages/home/summary/content/TooltipContent'
import { PaymentScheduleEnum } from 'src/types'
import { captureExceptionHelper } from 'src/utils'

interface DelayNextPaymentModalContentProps {
  chosenDate: Date | null
  setChosenDate: (date: Date) => void
  responseData: any
}

const DelayNextPaymentModalContent = ({
  chosenDate,
  setChosenDate,
  responseData,
}: DelayNextPaymentModalContentProps) => {
  const { t } = useTranslation()

  const { dateFormatLocal } = useLocalizedFormatters()

  const {
    start_date,
    end_date,
    holiday_list,
    incycle_upper_date,
    payment_schedule,
    monthly_payment_amount,
    repayment_frequency,
  } = responseData

  if (!responseData) {
    return <LoadingAnimation />
  }

  // TODO: figure out how to translate this
  const contractualDueDateText = payment_schedule // looks like 'On the 15th of every month'
  const currentPaymentDateText = dateFormatLocal(incycle_upper_date)
  const isMonthlyPaymentFrequency = repayment_frequency === PaymentScheduleEnum.ONCE_A_MONTH

  const validDates: Date[] = eachDayOfInterval({
    start: parse(start_date, 'yyyy-MM-dd', new Date()),
    end: parse(end_date, 'yyyy-MM-dd', new Date()),
  }).filter(date => {
    const formattedDate = format(date, 'yyyy-MM-dd') // Convert holiday_list dates to "yyyy-MM-dd" format
    return !holiday_list.includes(formattedDate) // Exclude dates present in holiday_list
  })

  return (
    <>
      <InfoMessage
        content={
          <Trans
            i18nKey="Portal.Components.modal.delayNextPayment.infoMessage"
            values={{ nextPaymentDate: currentPaymentDateText }}
            components={{
              underline: (
                <TooltipContainer
                  title={t('Portal.Components.tooltip.paymentDeferral.title')}
                  linkElement={
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      {t('Portal.Components.modal.delayNextPayment.paymentDeferral')}
                    </span>
                  }
                  content={
                    <PaymentDeferralContent
                      contractualDueDate={contractualDueDateText}
                      monthlyPaymentAmount={monthly_payment_amount}
                    />
                  }
                />
              ),
            }}
          />
        }
      />
      <FigDatePickerDay
        label={t('Portal.Components.modal.delayNextPayment.datePicker.label')}
        getIsInvalidDate={getIsInvalidDateFromRange(validDates)}
        calendarDate={chosenDate}
        setCalendarDate={setChosenDate}
        disableWeekends={isMonthlyPaymentFrequency ? true : false}
        name="delayNextPaymentDatePicker"
        placeholder={'yyyy-MM-dd'}
        inputFormat={'yyyy-MM-dd'}
      />
    </>
  )
}

export const DelayNextPaymentModal = () => {
  const { t } = useTranslation()
  const { closeAllModals, openModal, setDelayedNextPaymentDate } = useModalContext()
  const { loanDetailData } = usePortalContext()
  const { portalApiPaymentDeferralValidateSchedule } = usePortalApi()
  const [chosenDate, setChosenDate] = useState<Date | null>(null)
  if (loanDetailData?.system_date && !chosenDate) {
    setChosenDate(parse(loanDetailData.system_date, 'yyyy-MM-dd', new Date()))
  }

  const { isLoading: isValidatingDeferral, data: validateScheduleResponse } = useQuery(
    ['validateDeferralDate'],
    async () => {
      const data = await portalApiPaymentDeferralValidateSchedule(loanDetailData?.id || '')
      if (data?.incycle_upper_date) {
        setChosenDate(parse(data?.incycle_upper_date, 'yyyy-MM-dd', new Date()))
      }
      return data
    },
    {
      enabled: !!loanDetailData?.id, // run the query when loanDetailData is available
      useErrorBoundary: false,
      onError: (error: any) => {
        captureExceptionHelper(`Error delaying next payment: ${error}`, error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
      },
    },
  )

  const onSubmit = () => {
    setDelayedNextPaymentDate(chosenDate)
    openModal(PortalModalsEnum.CONFIRM_NEW_PAYMENT_DATE_MODAL)
  }

  return (
    <ActionModal
      title={t('Portal.Components.modal.delayNextPayment.title')}
      description={t('Portal.Components.modal.delayNextPayment.description', {
        nextPaymentDate: validateScheduleResponse?.current_payment_date,
      })}
      content={
        isValidatingDeferral ? (
          <LoadingAnimation />
        ) : (
          <DelayNextPaymentModalContent
            chosenDate={chosenDate}
            setChosenDate={setChosenDate}
            responseData={validateScheduleResponse}
          />
        )
      }
      buttonText={t('Portal.Components.modal.delayNextPayment.buttonText')}
      closeButtonHandler={closeAllModals}
      nextButtonHandler={onSubmit}
      nextButtonEnabled={!isValidatingDeferral}
    />
  )
}
