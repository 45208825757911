import { SignIn, useAuth, useClerk } from '@clerk/clerk-react'
import { Box, GlobalStyles } from '@mui/material'

import { logoImgSvg } from 'src/images'
import { FIG_HOMEPAGE_URL, PortalRoutesEnum } from 'src/portal/common'
import Footer from 'src/portal/layout/footer/Footer'
import Header from 'src/portal/layout/header/Header'
import theme from 'src/themes'
import PortalTheme from 'src/themes/portal'

export default function SignComponent() {
  const { signOut } = useClerk()
  const { isSignedIn } = useAuth()

  return (
    <Box sx={{ backgroundColor: PortalTheme.color.white, height: '100%', width: '100%' }}>
      {/* style the freshchat widget for portal layout */}
      <GlobalStyles
        styles={{
          width: '100%',
          [PortalTheme.breakpoints.up('md')]: {
            '.fc-widget-normal': { bottom: '48px !important', right: '48px !important' },
          },
          [PortalTheme.breakpoints.down('md')]: {
            '.fc-widget-normal': { bottom: '120px !important', right: '24px !important' },
          },
        }}
      />
      <Header signOut={() => signOut({ redirectUrl: '/portal' })} isSignedIn={isSignedIn} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 164px)',
          '.cl-internal-y44tp9': {
            // hide the "secured by clerk line" as requested
            display: 'none',
          },
        }}
      >
        <SignIn
          path={`/${PortalRoutesEnum.PORTAL_BASE}`}
          appearance={{
            layout: {
              logoImageUrl: logoImgSvg,
              logoLinkUrl: FIG_HOMEPAGE_URL,
            },
            variables: {
              colorPrimary: theme.color.brand1,
            },
          }}
        />
      </Box>

      <Footer />
    </Box>
  )
}
