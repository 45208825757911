import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  BrowserRouter,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Sentry from '@sentry/react'
import { Integration } from '@sentry/types'
import { ThemeProvider } from '@mui/material'

import { queryClient } from 'src/api'
import { GenericError } from 'src/components/Error/GenericError'
import {
  EnvEnum,
  ENVIRONMENT,
  SENTRY_DSN,
  SENTRY_RELEASE,
  IS_SSP_STANDALONE_PAGE,
} from 'src/utils/constants'
import { logoImg } from 'src/images'
import RootLayout from 'src/components/common/RootLayout'
import AppRouter from 'src/routers/index'
import SSPRouter from 'src/routers/ssp'
import { log } from 'src/utils'
import theme from 'src/themes'

const replay = Sentry.replayIntegration({
  blockAllMedia: false,
  maskAllText: false,
  maskAllInputs: false,
})

Sentry.init({
  dsn: SENTRY_DSN,
  normalizeDepth: 5,
  beforeSend: event => {
    event.tags = event.tags || {}
    event.tags.service = 'frontend'
    log.error('sentry event', event)
    return event
  },
  integrations: [
    Sentry.extraErrorDataIntegration({ depth: 10 }) as Integration,
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [500, 599],
      failedRequestTargets: ENVIRONMENT === EnvEnum.local ? ['http://localhost'] : [/fig\.ca/],
    }) as Integration,
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    replay,
  ],
  tracePropagationTargets:
    ENVIRONMENT === EnvEnum.local ? ['localhost'] : [`purple-api.${ENVIRONMENT}.fig.ca`],
  replaysOnErrorSampleRate: ENVIRONMENT !== EnvEnum.production ? 1.0 : 0,
  tracesSampleRate: 1.0,
  release: SENTRY_RELEASE,
  environment: ENVIRONMENT,
})

const genericFallbackErrorComponent = ({
  error,
}: {
  error: unknown
  componentStack: string
  eventId: string
}) => {
  const isLocalStorageError =
    error instanceof DOMException &&
    (error.name === 'SecurityError' || error?.message?.includes('localStorage'))
  return (
    <ThemeProvider theme={theme}>
      <RootLayout logo={{ src: logoImg, alt: 'Fig logo' }}>
        <GenericError isUncaughtError={true} isLocalStorageError={isLocalStorageError} />
      </RootLayout>
    </ThemeProvider>
  )
}

/**
 *
 */
export default function App() {
  if (IS_SSP_STANDALONE_PAGE) {
    return (
      <Sentry.ErrorBoundary fallback={genericFallbackErrorComponent}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <SSPRouter />
          </BrowserRouter>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    )
  }
  return (
    <Sentry.ErrorBoundary fallback={genericFallbackErrorComponent}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}
