import { Box, Chip, GlobalStyles, Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { parse } from 'date-fns'

import Footer from 'src/portal/layout/footer/Footer'
import Header from 'src/portal/layout/header/Header'
import PortalTheme from 'src/themes/portal'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { dateFormatCustom as dateFormatCustomWithLanguage } from 'src/utils/format'
import { SSP_DEPLOYMENT_DATE } from 'src/utils/constants'
import { figLogo } from 'src/images'
import { SupportedLanguage } from 'src/locales'

function Section({
  title,
  content,
  link,
  isNewSSP,
}: {
  title: string
  content: string
  link: string
  isNewSSP?: boolean
}) {
  const style: any = {}
  const orange = '#F59E0B'
  const { t } = useTranslation()

  if (isNewSSP) {
    style.backgroundColor = orange
    style.color = PortalTheme.color.brand1
  }

  return (
    <Grid2
      size={{ xs: 12, md: 6 }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '8px',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Box
        width="300px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom="70px"
      >
        <Typography textAlign="center" variant="h1" marginTop={'32px'} display="inline-block">
          {title}{' '}
          {isNewSSP ? (
            <Chip
              label={t('Portal.Intermittent.newText')}
              sx={{ backgroundColor: orange, color: PortalTheme.color.brand1 }}
            />
          ) : null}
        </Typography>

        <Typography textAlign="center" variant="body1" marginTop="32px" marginBottom="16px">
          {content}
        </Typography>

        <PrimaryCTA href={link} buttonText={t('Portal.Intermittent.login')} style={style} />
      </Box>
    </Grid2>
  )
}

export default function SignComponent() {
  const { t, i18n } = useTranslation()
  const language = i18n.resolvedLanguage as SupportedLanguage

  const dateStr = dateFormatCustomWithLanguage({
    date: parse(SSP_DEPLOYMENT_DATE, 'yyyy-MM-dd', new Date()),
    formatStr: language == 'fr' ? 'EEEE dd MMMM yyyy' : 'MMMM d, yyyy',
    language,
  })

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <GlobalStyles
        styles={{
          width: '100%',
          [PortalTheme.breakpoints.up('md')]: {
            '.fc-widget-normal': { bottom: '48px !important', right: '48px !important' },
          },
          [PortalTheme.breakpoints.down('md')]: {
            '.fc-widget-normal': { bottom: '120px !important', right: '24px !important' },
          },
          backgroundColor: PortalTheme.color.brand1,
        }}
      />
      <Header signOut={() => ''} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '.cl-internal-y44tp9': {
            display: 'none',
          },
          [PortalTheme.breakpoints.up('md')]: {
            backgroundColor: PortalTheme.color.brand1,
            paddingLeft: '40px',
            paddingRight: '40px',
            height: 'calc(100vh - 164px)',
          },
          [PortalTheme.breakpoints.down('sm')]: {
            paddingBottom: '90px',
          },
        }}
        id="container"
      >
        <Grid2
          container
          sx={{
            backgroundColor: PortalTheme.color.grey100,
            paddingY: '48px',
            paddingX: '36px',
            maxWidth: '900px',
            borderRadius: '8px',
          }}
          spacing={4}
        >
          <Grid2 size={12} display="flex" justifyContent="center" alignItems="center">
            <img
              src={figLogo}
              alt={t('Portal.Header.alt.figLogo')}
              aria-label={t('Portal.Header.alt.figLogo')}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography
              textAlign="center"
              variant="h1"
              sx={{
                color: PortalTheme.color.brand1,
                [PortalTheme.breakpoints.up('sm')]: {
                  fontWeight: 600,
                  fontSize: 36,
                  marginBottom: '24px',
                },
              }}
            >
              {t('Portal.Intermittent.title')}
            </Typography>
          </Grid2>

          <Grid2 container size={12}>
            <Section
              title={t('Portal.Intermittent.sectionTitle', { version: '1.0' })}
              content={t('Portal.Intermittent.sectionContentV1', {
                dateSection: dateStr,
              })}
              link="https://portal.fig.ca/"
            />

            <Section
              title={t('Portal.Intermittent.sectionTitle', { version: '2.0' })}
              content={t('Portal.Intermittent.sectionContentV2', {
                dateSection: dateStr,
              })}
              link="https://apply.fig.ca/portal/"
              isNewSSP
            />
          </Grid2>
        </Grid2>
      </Box>

      <Footer />
    </Box>
  )
}
