import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { LabelText } from 'src/portal/components/LabelText'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import PortalTheme from 'src/themes/portal'

export const DueDateContainer = ({ dueDate }: { dueDate: string }) => {
  const { dateFormatLocal } = useLocalizedFormatters()
  const { t } = useTranslation()
  return (
    <SpacedRow>
      <LabelText text={t('Portal.Home.label.summary.outstandingAmountDueDate')} />
      <Typography variant="body2" color={PortalTheme.color.grey9} py="6px">
        {dateFormatLocal(dueDate)}
      </Typography>
    </SpacedRow>
  )
}
