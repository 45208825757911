import { Checkbox, FormControlLabel, Link, Typography } from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { ConnectionStepEnum, LinkBankAccountStatusEnum } from 'src/portal/pages/flinks/types'
import PortalTheme from 'src/themes/portal'
import { captureExceptionHelper } from 'src/utils'
import { pdfFileDownload } from 'src/utils/files'

const ConfirmNewPaymentMethodContent = ({
  nextButtonEnabled,
  setNextButtonEnabled,
  padAgreementContent,
}: {
  nextButtonEnabled: boolean
  setNextButtonEnabled: (value: boolean) => void
  padAgreementContent: string
}) => {
  const { loanDetailData, paymentDetailData } = usePortalContext()
  const [consentCheckboxChecked, setConsentCheckboxChecked] = useState(false)

  const fetchLoanAgreement = async () => {
    pdfFileDownload(padAgreementContent, `PAD Agreement.pdf`)
  }

  const checkBoxHandler = () => {
    setNextButtonEnabled(!nextButtonEnabled)
    setConsentCheckboxChecked(!consentCheckboxChecked)
  }
  if (!loanDetailData || !paymentDetailData) {
    return <LoadingAnimation />
  }
  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={consentCheckboxChecked} onChange={checkBoxHandler} />}
        label={
          <Trans
            i18nKey="Portal.Components.modal.confirmNewPaymentMethod.label.consentCheckbox"
            components={{
              typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
              linkComponent: (
                <Link color={PortalTheme.color.brand1} onClick={fetchLoanAgreement}>
                  PAD agreement
                </Link>
              ),
            }}
          />
        }
      />
    </>
  )
}

export const ConfirmNewPaymentMethod = ({
  setStep,
  institutionName,
}: {
  setStep: (value: string) => void
  institutionName?: string
}) => {
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const { closeModal, closeAllModals } = useModalContext()
  const {
    portalApiChangePaymentMethodGetPadAgreement,
    portalApiChangePaymentMethodLinkBankAccount,
  } = usePortalApi()
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false)
  const { data: padAgreementData, isLoading: isPadAgreementLoading } = useQuery(
    ['padAgreement', loanDetailData?.id],
    () =>
      portalApiChangePaymentMethodGetPadAgreement(loanDetailData?.id || '', {
        application_id: loanDetailData?.application_id || '',
      }),
    {
      onError: (error: any) => {
        captureExceptionHelper('Error getting PAD agreement', error)
      },
    },
  )

  const { mutate: linkBankAccount, isLoading: isLinkBankAccountLoading } = useMutation(
    ({ loanId }: { loanId: string }) =>
      portalApiChangePaymentMethodLinkBankAccount(loanId, {
        application_id: loanDetailData?.application_id || '',
      }),
    {
      onSuccess: data => {
        if (data.status === LinkBankAccountStatusEnum.ALREADY_LINKED) {
          setStep(ConnectionStepEnum.ACCOUNT_ALREADY_LINKED)
        } else {
          setStep(ConnectionStepEnum.BANK_SUCCESS)
        }
      },
      onError: (error: any) => {
        captureExceptionHelper('Error connecting bank account', error)
        setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
      },
    },
  )
  const handleConfirm = () => {
    linkBankAccount({ loanId: loanDetailData?.id || '' })
  }

  return (
    <ActionModal
      title={t('Portal.Components.modal.confirmNewPaymentMethod.title')}
      //   TODO: find out how to get new bankAccountName
      description={t('Portal.Components.modal.confirmNewPaymentMethod.description', {
        bankAccountName: institutionName || '',
      })}
      content={
        isPadAgreementLoading || isLinkBankAccountLoading ? (
          <LoadingAnimation />
        ) : (
          <ConfirmNewPaymentMethodContent
            nextButtonEnabled={nextButtonEnabled}
            setNextButtonEnabled={setNextButtonEnabled}
            padAgreementContent={padAgreementData?.content || ''}
          />
        )
      }
      buttonText={t('Portal.Components.modal.confirmNewPaymentMethod.buttonText')}
      closeButtonHandler={closeAllModals}
      backButtonHandler={closeModal}
      nextButtonHandler={handleConfirm}
      nextButtonEnabled={nextButtonEnabled && (!isPadAgreementLoading || !isLinkBankAccountLoading)}
    />
  )
}
