import { useAuth } from '@clerk/clerk-react'
import { createContext, FC, ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  UseMutationResult,
  useQuery,
} from 'react-query'

import {
  FigChildrenContainer,
  FigContentContainer,
  StyledDiv,
} from 'src/components/common/RootLayout'
import { NetworkProvider } from 'src/contexts'
import type {
  BorrowerDetailSchema,
  LoanQueryResponseSchema,
  PaymentDetailResponseSchema,
  PaymentScheduleChangeCompleteRequestSchema,
  PaymentScheduleChangeProcessSelectedDateRequestSchema,
  PaymentScheduleChangeProcessSelectedDateResponseSchema,
  PaymentScheduleChangeValidationResponseSchema,
  PaymentScheduleValidationRequestSchema,
  RepaymentScheduleResponseSchema,
  SuccessSchema,
  TransactionHistoryResponseSchema,
} from 'src/portal/api/api.schemas'
import { GenericError } from 'src/portal/components/GenericError'
import { ModalProvider } from 'src/portal/contexts/modal'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import Layout from 'src/portal/layout'

type PaymentScheduleChangeProps = {
  validateMutation: UseMutationResult<
    PaymentScheduleChangeValidationResponseSchema,
    unknown,
    PaymentScheduleValidationRequestSchema,
    unknown
  >
  completeMutation: UseMutationResult<
    SuccessSchema,
    unknown,
    PaymentScheduleChangeCompleteRequestSchema,
    unknown
  >
  processSelectedDateMutation: UseMutationResult<
    PaymentScheduleChangeProcessSelectedDateResponseSchema,
    unknown,
    PaymentScheduleChangeProcessSelectedDateRequestSchema,
    unknown
  >
}

export type PortalContextProps = {
  portalApiGetBorrowerDetailRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<BorrowerDetailSchema>>
  portalApiGetBorrowerDetailIsSuccess: boolean
  borrowerDetailData?: BorrowerDetailSchema
  portalApiGetLoanDetailRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<LoanQueryResponseSchema>>
  portalApiGetLoanDetailIsSuccess: boolean
  loanDetailData?: LoanQueryResponseSchema
  isPaymentProtectionEnabled: boolean | undefined

  portalApiGetLoanRepaymentScheduleRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<RepaymentScheduleResponseSchema[]>>
  portalApiGetLoanRepaymentScheduleIsSuccess: boolean
  loanRepaymentScheduleData?: RepaymentScheduleResponseSchema[]

  portalApiGetLoanTransactionHistoryRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<TransactionHistoryResponseSchema[]>>
  portalApiGetLoanTransactionHistoryIsSuccess: boolean
  loanTransactionHistoryData?: TransactionHistoryResponseSchema[]
  portalApiGetPaymentDetailRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<PaymentDetailResponseSchema[]>>
  portalApiGetPaymentDetailIsSuccess: boolean
  paymentDetailData: PaymentDetailResponseSchema[] | undefined
  portalApiPaymentScheduleChange: PaymentScheduleChangeProps
}

export const PortalContext = createContext<PortalContextProps>({} as PortalContextProps)

interface Props {
  children: ReactNode
}

export const PortalProvider: FC<Props> = ({ children }) => {
  const {
    portalApiGetBorrowerDetail,
    portalApiGetLoanDetail,
    portalApiGetLoanRepaymentSchedule,
    portalApiGetLoanTransactionHistory,
    portalApiGetPaymentDetail,
    portalApiPaymentScheduleChangeValidateSchedule,
    portalApiPaymentScheduleChangeProcessSelectedDate,
    portalApiPaymentScheduleChangeComplete,
  } = usePortalApi()
  const { isSignedIn, userId } = useAuth()

  const {
    refetch: portalApiGetBorrowerDetailRefetch,
    isSuccess: portalApiGetBorrowerDetailIsSuccess,
    data: borrowerDetailData,
  } = useQuery<BorrowerDetailSchema>(
    ['portalApiGetBorrowerDetail', userId],
    () => portalApiGetBorrowerDetail(),
    {
      enabled: !!isSignedIn,
      useErrorBoundary: false,
    },
  )

  const {
    refetch: portalApiGetLoanDetailRefetch,
    isSuccess: portalApiGetLoanDetailIsSuccess,
    data: loanDetailData,
  } = useQuery<LoanQueryResponseSchema>(
    ['portalApiGetLoanDetailRefetch', userId],
    () => portalApiGetLoanDetail(),
    {
      enabled: !!isSignedIn,
      useErrorBoundary: false,
    },
  )

  const {
    refetch: portalApiGetLoanRepaymentScheduleRefetch,
    isSuccess: portalApiGetLoanRepaymentScheduleIsSuccess,
    data: loanRepaymentScheduleData,
  } = useQuery<RepaymentScheduleResponseSchema[]>(
    ['portalApiGetLoanRepaymentSchedule', loanDetailData?.id],
    () => portalApiGetLoanRepaymentSchedule(loanDetailData?.id || ''),
    {
      enabled: !!loanDetailData?.id,
      useErrorBoundary: false,
    },
  )

  const {
    refetch: portalApiGetLoanTransactionHistoryRefetch,
    isSuccess: portalApiGetLoanTransactionHistoryIsSuccess,
    data: loanTransactionHistoryData,
  } = useQuery<TransactionHistoryResponseSchema[]>(
    ['portalApiGetLoanTransactionHistory', loanDetailData?.id],
    () => portalApiGetLoanTransactionHistory(loanDetailData?.id || ''),
    {
      enabled: !!loanDetailData?.id,
      useErrorBoundary: false,
    },
  )

  const {
    refetch: portalApiGetPaymentDetailRefetch,
    isSuccess: portalApiGetPaymentDetailIsSuccess,
    data: paymentDetailData,
  } = useQuery<PaymentDetailResponseSchema[]>(
    ['portalApiGetPaymentDetailRefetch', loanDetailData?.id],
    () => {
      if (loanDetailData?.id) {
        return portalApiGetPaymentDetail(loanDetailData?.id || '')
      }
      return []
    },
    {
      enabled: !!loanDetailData?.id,
      useErrorBoundary: false,
    },
  )

  const PaymentScheduleChangeValidateScheduleMutation = useMutation(
    ['portalApiPaymentScheduleChangeValidateSchedule', loanDetailData?.id],
    (payload: PaymentScheduleValidationRequestSchema) => {
      return portalApiPaymentScheduleChangeValidateSchedule(loanDetailData?.id || '', payload)
    },
    {
      useErrorBoundary: false,
    },
  )

  const PaymentScheduleChangeProcessSelectedDateMutation = useMutation(
    ['portalApiPaymentScheduleChangeProcessSelectedDate', loanDetailData?.id],
    (payload: PaymentScheduleChangeProcessSelectedDateRequestSchema) => {
      return portalApiPaymentScheduleChangeProcessSelectedDate(loanDetailData?.id || '', payload)
    },
    {
      useErrorBoundary: false,
    },
  )

  const PaymentScheduleChangeCompleteMutation = useMutation(
    ['portalApiPaymentScheduleChangeComplete', loanDetailData?.id],
    (payload: PaymentScheduleChangeCompleteRequestSchema) => {
      return portalApiPaymentScheduleChangeComplete(loanDetailData?.id || '', payload)
    },
    {
      useErrorBoundary: false,
    },
  )

  const value = {
    borrowerDetailData,
    portalApiGetBorrowerDetailRefetch,
    portalApiGetBorrowerDetailIsSuccess,
    portalApiGetLoanDetailRefetch,
    portalApiGetLoanDetailIsSuccess,
    portalApiGetLoanRepaymentScheduleRefetch,
    portalApiGetLoanRepaymentScheduleIsSuccess,
    loanRepaymentScheduleData,
    loanDetailData,
    isPaymentProtectionEnabled: loanDetailData?.payment_protection_applied,
    portalApiGetLoanTransactionHistoryRefetch,
    portalApiGetLoanTransactionHistoryIsSuccess,
    loanTransactionHistoryData,
    portalApiGetPaymentDetailRefetch,
    portalApiGetPaymentDetailIsSuccess,
    paymentDetailData,
    portalApiPaymentScheduleChange: {
      validateMutation: PaymentScheduleChangeValidateScheduleMutation,
      processSelectedDateMutation: PaymentScheduleChangeProcessSelectedDateMutation,
      completeMutation: PaymentScheduleChangeCompleteMutation,
    },
  }

  return (
    <ErrorBoundary
      fallback={
        <NetworkProvider>
          <Layout>
            <StyledDiv id="fig-root-container">
              <FigContentContainer>
                <FigChildrenContainer id="fig-content-container">
                  <GenericError isUncaughtError={false} />
                </FigChildrenContainer>
              </FigContentContainer>
            </StyledDiv>
          </Layout>
        </NetworkProvider>
      }
    >
      <PortalContext.Provider value={value}>
        <ModalProvider>
          <NetworkProvider>{children}</NetworkProvider>
        </ModalProvider>
      </PortalContext.Provider>
    </ErrorBoundary>
  )
}

export default PortalProvider
