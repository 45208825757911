import { FormControlLabel, Grid2, Radio, Typography } from '@mui/material'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import PortalTheme from 'src/themes/portal'

interface PaymentOptionSelectProps {
  title: string
  description: string
  value: string
  amount?: number
}
export const PaymentOptionSelect = ({
  title,
  description,
  value,
  amount,
}: PaymentOptionSelectProps) => {
  const { currencyFormat } = useLocalizedFormatters()
  //   TODO: figure out border color and bold
  return (
    <FormControlLabel
      sx={{
        border: `1px solid ${PortalTheme.color.grey4}`,
        borderRadius: '3px',
        mb: '16px',
        p: '16px',
        marginLeft: 0, //override default FormControlLabel margins
        marginRight: 0,
      }}
      value={value}
      control={
        <Radio
          sx={{
            '&.Mui-checked': {
              '& + .MuiFormControlLabel-root': {
                border: `2px solid ${PortalTheme.color.brand1}`,
              },
            },
          }}
        />
      }
      label={
        <>
          <SpacedRow>
            <Grid2 size={9}>
              <Typography variant="body2" fontWeight={500} color={PortalTheme.color.grey10}>
                {title}
              </Typography>
            </Grid2>
            {amount && (
              <Grid2 size={3}>
                <Typography variant="body2" color={PortalTheme.color.grey6}>
                  {currencyFormat(amount)}
                </Typography>
              </Grid2>
            )}
          </SpacedRow>
          <Typography variant="body3" color={PortalTheme.color.grey6}>
            {description}
          </Typography>
        </>
      }
    />
  )
}
