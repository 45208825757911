import { Route } from 'react-router-dom'

import { SentryRoutes } from 'src/routers/common'
import PortalSignin from 'src/portal/pages/auth/signinSSP'

export default function AppRouter() {
  return (
    <SentryRoutes>
      <Route path="/" index element={<PortalSignin />} />
    </SentryRoutes>
  )
}
