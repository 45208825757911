export enum PortalRoutesEnum {
  PORTAL_BASE = 'portal',
  PORTAL_INDEX = '',
  PORTAL_ERROR = 'error',
  PORTAL_PROFILE = 'profile',
  PORTAL_SIGN_IN = 'signin',
  PORTAL_PAYMENTS = 'payments',
  PORTAL_DOCUMENTS = 'documents',
  PORTAL_PAYMENT_PROTECTION = 'payment-protection',
  PORTAL_LOAN_INCREASE = 'loan-increase',
  PORTAL_HELP = 'help',
  PORTAL_TERMS = 'terms',
  PORTAL_CHANGE_BANK_ACCOUNT = 'bank-account/update',
}

export enum PortalModalsEnum {
  AUTOMATIC_PAYMENTS_DETAIL = 'automaticPaymentsDetail',
  MANUAL_PAYMENT_MODAL = 'manualPaymentModal',
  CONFIRM_PAYMENT_MODAL = 'confirmPaymentModal',
  PAYMENT_SUCCESS_MODAL = 'paymentSuccessModal',
  PAYMENT_ERROR_MODAL = 'paymentErrorModal',
  CHANGE_PAYMENT_RESCHEDULE_START = 'changePaymentIndex',
  CHANGE_PAYMENT_RESCHEDULE_EDIT = 'changePaymentRescheduleEdit',
  CHANGE_PAYMENT_RESCHEDULE_CONFIRM = 'changePaymentRescheduleConfirm',
  CHANGE_PAYMENT_RESCHEDULE_SUCCESS = 'changePaymentRescheduleSuccess',
  CHANGE_PAYMENT_METHOD_START = 'changePaymentMethodStart',
  DELAY_NEXT_PAYMENT_MODAL = 'delayNextPaymentModal',
  CONFIRM_NEW_PAYMENT_DATE_MODAL = 'confirmNewPaymentDateModal',
  PAYMENT_DEFERRAL_SUCCESS_MODAL = 'paymentDeferralSuccessModal',
}

export enum LoanStatusEnum {
  GOOD = 'Active - Good Standing',
  OVERDUE = 'Active - Bad Standing',
  CLOSED = 'Closed - Obligations met',
  CLOSED_REFINANCED = 'Closed - Refinanced',
}

export enum TransactionStatusEnum {
  SUCCESS = 'success',
  PROCESSING = 'processing',
  FAILED = 'failed',
}

export enum PaymentMethodEnum {
  MANUAL_PAYMENT = 'Manual payment',
  BILL_PAYMENT = 'Bill payment',
  AUTOMATIC_PAYMENT = 'Automatic payment',
  INTERAC_PAYMENT = 'Interac payment',
  DISBURSAL = 'Disbursal',
  TBD = 'TBD',
}

export enum DropdownMenuEnum {
  USER = 'user',
  LANGUAGE = 'language',
}

export enum DropdownMenuItemUserEnum {
  PROFILE = 'profile',
  LOGOUT = 'logout',
}

export const TABLET_BREAKPOINT = 768

export const FIG_HOMEPAGE_URL = 'https://fig.ca'
