import { Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { CertificateOfInsuranceDownloadResponseSchema } from 'src/portal/api/api.schemas'
import { RoundedBox } from 'src/portal/components/RoundedBox'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import { StatusIconEnum } from 'src/portal/components/types'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'
import { DocumentList } from 'src/portal/pages/paymentProtection/components/DocumentList'
import { ModalLinks } from 'src/portal/pages/paymentProtection/components/ModalLinks'
import { PaymentProtectionAccordion } from 'src/portal/pages/paymentProtection/components/PaymentProtectionAccordion'
import theme from 'src/themes'

const PortalPaymentProtection = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { portalApiPaymentProtectionGetCertificateOfInsurance } = usePortalApi()
  const { loanDetailData } = usePortalContext()

  const { isLoading: isDocumentLoading, data: documentData } =
    useQuery<CertificateOfInsuranceDownloadResponseSchema>(
      ['portalApiPaymentProtectionGetCertificateOfInsurance', loanDetailData?.id],
      () => portalApiPaymentProtectionGetCertificateOfInsurance(loanDetailData?.id || ''),
      {
        enabled: !!loanDetailData?.id,
        useErrorBoundary: false,
      },
    )

  if (isDocumentLoading || !loanDetailData || !documentData) {
    return <LoadingAnimation />
  }

  return (
    <RoundedBox>
      <Typography variant="h1">{t('Portal.PaymentProtection.title')}</Typography>
      <StatusBadge
        iconVariant={StatusIconEnum.ACTIVE}
        label={t('Portal.PaymentProtection.activeBadge')}
      />
      <StretchDivider />
      <DocumentList
        documentDate={loanDetailData?.loan_disbursal_date}
        documentData={documentData?.content}
      />
      <StretchDivider />
      <ModalLinks isMobile={isMobile} />
      <PaymentProtectionAccordion isMobile={isMobile} />
    </RoundedBox>
  )
}

export default PortalPaymentProtection
