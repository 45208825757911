import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, Divider, Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  AccordionHeaderStyle,
  IconStyle,
  MainPaymentProtectionListItem,
  ModalAccordionGrid,
  ModalAccordionSummary,
} from 'src/components/PaymentProtection/styles'
import { briefcaseIcon, heartIcon } from 'src/images'
import theme from 'src/themes'

export const PaymentProtectionAccordion = ({ isMobile }: { isMobile: boolean }) => {
  const { t } = useTranslation()
  const accordions = [
    {
      id: 'FirstAccordion',
      icon: briefcaseIcon,
      title: t(`PaymentProtection.FirstAccordion.title`),
      subtitle: t(`PaymentProtection.FirstAccordion.subtitle`),
      points: [
        t(`PaymentProtection.FirstAccordion.pointone`),
        t(`PaymentProtection.FirstAccordion.pointtwo`),
        t(`PaymentProtection.FirstAccordion.pointthree`),
      ],
    },
    {
      id: 'SecondAccordion',
      icon: heartIcon,
      title: t(`PaymentProtection.SecondAccordion.title`),
      subtitle: t(`PaymentProtection.SecondAccordion.subtitle`),
      points: [
        t(`PaymentProtection.SecondAccordion.pointone`),
        t(`PaymentProtection.SecondAccordion.pointtwo`),
        t(`PaymentProtection.SecondAccordion.pointthree`),
        t(`PaymentProtection.SecondAccordion.pointfour`),
        t(`PaymentProtection.SecondAccordion.pointfive`),
      ],
    },
  ]
  return (
    <>
      {accordions.map(({ id, icon, points, title, subtitle }) => (
        <Grid2 key={id} marginTop="32px" sx={{ boxShadow: '0px 4px 16px 0px #00000014' }}>
          <Accordion sx={{ boxShadow: 0 }}>
            <ModalAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id={`${id}-header`}
              aria-controls="panel-content"
              isMobile={isMobile}
            >
              <ModalAccordionGrid isMobile={isMobile}>
                <IconStyle src={icon} alt={title} />
              </ModalAccordionGrid>
              <AccordionHeaderStyle>
                <Typography variant="body2" fontWeight="bold" sx={{ color: theme.color.grey9 }}>
                  {title}
                </Typography>
                <Typography variant="body2" sx={{ color: theme.color.grey9 }}>
                  {subtitle}
                </Typography>
              </AccordionHeaderStyle>
            </ModalAccordionSummary>
            <AccordionDetails>
              {points.map(point => (
                <MainPaymentProtectionListItem key={point} text={point} isMobile={isMobile} />
              ))}
            </AccordionDetails>
          </Accordion>
          <Divider variant="middle" sx={{ borderRadius: '20px' }} />
        </Grid2>
      ))}
    </>
  )
}
