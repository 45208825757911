const portalTranslation = {
  Portal: {
    title: 'Fig | Customer Portal',
    common: {
      dateFormat: 'MMM d, yyyy',
    },
    Header: {
      alt: {
        figLogo: 'Fig logo',
        userIcon: 'User icon',
        languageDropdown: 'Toggle dropdown',
      },
      label: {
        languageDropdown: 'Language',
        profileSettings: 'Profile settings',
        logout: 'Log out',
      },
    },
    Menu: {
      links: {
        referral: `https://fig.ca/en/referral/`,
        terms: `https://fig.ca/en/legal/terms-of-use/`,
        privacy: `https://fig.ca/en/legal/privacy-statement/`,
        helpCenter: `https://support.fig.ca/en/support/home`,
      },
      alt: {
        homeIcon: 'Home icon',
        paymentsIcon: 'Payments icon',
        giftIcon: 'Gift icon',
        documentsIcon: 'Documents icon',
        moreIcon: 'More icon',
        paymentProtectionIcon: 'Payment protection icon',
        loanIncreaseIcon: 'Loan increase icon',
        helpCenterIcon: 'Help center icon',
        termsIcon: 'Terms of use icon',
        notificationIcon: 'Notification icon',
        dividerIcon: 'Menu divider',
      },
      label: {
        home: 'Home',
        payments: 'Payments',
        documents: 'Documents',
        managePayments: 'Manage payments',
        viewDocuments: 'View documents',
        more: 'More',
        paymentProtection: 'Payment protection',
        loanIncrease: 'Request a loan amount increase',
        helpCenter: 'Help center',
        terms: 'Terms of use',
      },
    },
    Intermittent: {
      title: 'Welcome to your Fig account',
      sectionTitle: `Customer Portal {{version}}`,
      sectionContentV1: `Log in here if you booked your loan before {{ dateSection }}`,
      sectionContentV2: `Log in here if you booked your loan on or after {{ dateSection }}`,
      newText: 'New',
      login: 'Log in',
    },
    Home: {
      label: {
        tab: {
          summary: 'Summary',
          transactions: 'Transactions',
          upcomingPayments: 'Upcoming Payments',
        },
        summary: {
          title: 'Summary',
          loanStatus: {
            good: 'Good standing',
            overdue: 'Payment overdue',
            closed: 'Closed',
          },
          remainingBalance: 'Remaining balance',
          loanClosedMessage: `
      Your loan has been fully paid off. No future payment required.
          `,
          outstandingAmount: `Outstanding amount`,
          outstandingAmountDueDate: `Due date`,
          upcomingPaymentAmount: 'Upcoming payment amount',
          upcomingPaymentDate: 'Upcoming payment date',
          automaticPaymentsOn: 'Automatic payments: On',
          automaticPaymentsOff: 'Automatic payments: Off',
          paymentProtectionActive: 'Payment protection: Active',
          loanDetails: 'Loan details',
          loanId: 'Loan ID',
          loanAmount: 'Loan amount',
          interestRate: 'Interest rate',
          loanTerm: 'Loan term',
          loanTermInMonths: '{{count}} months',
          loanStartDate: 'Loan start date',
          loanClosedDate: `Loan closed date`,
          payNow: `Pay now`,
          payOutstandingAmount: `Pay outstanding amount`,
        },
        transactions: {
          title: `Transactions`,
          description: `Below, you can see all payments you’ve made, including scheduled payments and one time payments, as well as additional fees on your account.`,
          status: {
            processing: 'Processing',
            failed: 'Failed',
          },
          message: {
            processing: `Please ensure sufficient funds are available in your account until the payment is processed within 2–3 business days.`,
          },
        },
        upcomingPayments: {
          title: `Upcoming Payments`,
          description: `If you have made a lump sum payment, your monthly payment amount won’t be reduced, but your loan term will be shortened.`,
          descriptionWithPaymentProtection: `Your Payment Protection charges are included only in your next upcoming payment. For all other payments listed below, the charges will be added after the previous payment has been processed.`,
          remainingBalance: `Remaining balance:`,
        },
        greeting: {
          morning: 'Good morning',
          afternoon: 'Good afternoon',
          evening: 'Good evening',
        },
      },
    },
    Payments: {
      label: {
        title: `Manage your payments`,
        description: `Choose an option below`,
        options: {
          manualPayment: {
            title: `Make a manual payment`,
            description:
              'Want to pay off your loan faster? You can make an extra payment, or pay off your loan without penalties.',
          },
          overduePaymentDescription: `Choose to pay outstanding amount, pay a different amount or pay off your loan without penalties.`,
          manualPaymentUserDescription: `Choose to pay next payment, make an extra payment, or pay off your loan without penalties.`,
          delayNextPayment: {
            title: `Delay next payment`,
            description:
              'Need more time to make your next payment? You can delay your next payment by up to 1 month, once every 12 months.',
          },
          changePaymentSchedule: {
            title: `Change payment schedule`,
            description: `Choose the payment frequency and date that suits you, for regular payments.`,
          },
          changePaymentMethod: {
            title: `Change payment method`,
            description: `Update your bank account to process future payments.`,
          },
        },
        overdueMessage: `Your payment is overdue. Please pay your outstanding amount as soon as possible to avoid additional interest and negative impact on your credit score.`,
      },
    },
    Documents: {
      title: 'Documents',
      subtitle: 'Below, you can see all your documents for {{loanName}}',
      download: 'Download',
      loanAgreement: 'Loan Agreement',
      padAgreement: 'PAD Agreement',
    },
    Reschedule: {
      title: 'Change payment schedule',
      subtitle: '',
      change: {
        sectionTitle:
          'Your change will take place from your next payment cycle, starting {{ currentDate }}',
        headerTitle: 'Schedule change start date',
        buttonText: 'Confirm and continue',
        sectionContent: 'Next billing cycle start date',
      },
      delay: {
        sectionTitle: 'We noticed you have scheduled payments in the current billing cycle:',
        headerTitle: 'Scheduled payments',
        buttonText: 'Delay next payment',
        sectionContent: 'Billing cycle end date',
        footerText:
          'If you need to reschedule the payment(s) in your current billing cycle, please go to ‘Delay next payment’.',
      },
      EditPaymentSchedule: {
        title: 'Change payment schedule',
        frequencyTitle:
          'Your change will take place from your next payment cycle, starting {{ startDate }}',
        buttonNext: 'Next',
      },
      ConfirmSchedule: {
        title: 'Confirm new payment schedule',
        frequencyTitle:
          'We will withdraw {{ withdrawAmount }} {{ duration }} starting on {{ startDate }} from {{ paymentMethod }}',
        currentPayment: 'Upcoming payment in this payment cycle',
        upcomingPayment: 'Upcoming payment starting from next payment cycle',
        buttonConfirm: 'Confirm',
        notification:
          'If your payment date falls on weekend or holiday, it will be processed on the next business day.',
        padAgreement: {
          text: 'I agree to the updated <2>{{loanAgreementLabel}}</2>',
          link: 'PAD agreement.',
        },
        ScheduleFrequency: {
          weekly: 'every week',
          biweekly: ' every two weeks',
          twiceAMonth: ' twice a month',
          monthly: 'every month',
        },
        twiceAMonthText: `Twice a month (1st and 16th)`,
      },
      Success: {
        title: 'Payment schedule updated successfully ',
        buttonConfirm: 'Done',
      },
    },
    CreditRenewal: {
      title: 'Loan Amount Increase Request',
      subtitle: 'Secured application',
      eligibility: {
        title: 'Eligibility',
        content:
          'You must be a borrower in good standing and meet certain criteria as defined by Fig. You can request a loan amount increase from $2000 up to the unused amount from your original loan offer',
      },
      howItWorks: {
        title: 'How it works',
        content:
          'Rest assured the process is simple and no hard credit check is required. You’ll receive the additional funds in your account within 2 business days. Your current loan will be closed and a new loan will be opened reflecting the new total amount. <>Learn more</>',
      },
      button: 'Check my eligibility',
    },
    PaymentProtection: {
      title: `Payment protection plan`,
      activeBadge: `Active`,
      download: `Download`,
      certificateOfInsurance: `Certificate of Insurance`,
      documentName: `Certificate of Insurance`,
    },
    Components: {
      alt: {
        infoCircleIcon: 'Information icon',
        questionCircleIcon: 'Question mark icon',
        statusIcon: 'Status icon',
        chevronIcon: 'Chevron icon',
        checkmarkIcon: 'Checkmark icon',
        errorIcon: 'Error icon',
      },
      label: {
        viewDetails: 'View details',
        manage: 'Manage',
        learnMore: 'Learn more',
        showDetails: 'Show details',
        hideDetails: 'Hide details',
      },
      tooltip: {
        title: {
          accountStatus: 'Account status',
          remainingBalance: 'Remaining balance',
          loanId: 'Loan ID',
          loanTerm: 'Loan term',
          paymentDetails: 'Payment details',
          upcomingPaymentAmount: `Upcoming payment amount`,
          upcomingPaymentDate: `Upcoming payment date`,
          interestRate: `Interest`,
        },
        content: {
          accountStatus: `<typography>Your account status will show either:<br/><br/><strong>Good standing</strong> - All payments up to your last due date have been received<br/><br/><strong>Payment overdue</strong> - One or more expected payments have been missed<br/><br/><strong>Closed</strong> - Your loan has been paid off<br/><br/>If you see a "Your payment is overdue" message, please pay your outstanding amount as soon as possible or contact our support team to arrange for a payment to return your account to good standing.</typography>`,
          remainingBalance: `<typography>Your loan balance will increase as daily interest is calculated and decrease as payments are applied. If you are looking to pay off your loan, this is the amount due as of today.</typography>`,
          loanId: `<typography>Use this number as your account number when you try to make a bill payment through your bank.</typography>`,
          loanTerm: `<typography>If you make a lump sum payment or have a repayment schedule more frequent than once a month, your actual repayment term may be shorter than originally planned. However, if you miss or delay any payments, this may extend your repayment term beyond the initially scheduled end date.</typography>`,
          upcomingPaymentAmount: `<typography>This is your regular, recurring payment amount based on your payment frequency and the details of your loan.<br/><br/>This amount will also include any fees as a result of missed payments.</typography>`,
          upcomingPaymentDate: `<typography>To delay your next payment, please navigate to the Payments page and select the <linkComponent>Delay next payment</linkComponent> feature.</typography>`,
          interestRate: `<typography>Interest on your Fig loan is calculated daily. This means that each day, a fractional percentage of your annual interest rate is added to the principal your existing loan balance.</typography>`,
        },
        label: {
          principalRemaining: 'Principal remaining',
          interestAccrued: 'Interest accrued since last payment',
          feesAccrued: 'Fees accrued since last payment',
          total: 'Total',
          principal: 'Principal',
          interest: `Interest`,
          creditCharges: 'Credit charges',
          paymentProtectionPremium: 'Payment protection premium',
          paymentProtectionPremiumTax: 'Payment protection premium tax',
          nsfFees: 'NSF fees',
          totalAmount: 'Total amount',
        },
        paymentDeferral: {
          title: `Payment deferral`,
          content: `<typography>When your loan is booked, we establish what is called the billing cycle end date. This is the date you must make monthly payment in full by.<br/><br/>If monthly payment amount can’t be fulfilled by billing cycle end date, a payment deferral will incur.<br/><br/>You can defer your payment once per 12 month. When you defer your payment, your loan status and credit report will remain in good standing. However, your maturity date will be expanded and overall interest will increase.</typography>`,
          label: {
            monthlyPaymentDetails: `Your monthly payment details`,
            contractualDueDate: `Contractual due date`,
            monthlyPaymentAmount: `Monthly payment amount`,
          },
        },
      },
      modal: {
        automaticPayments: {
          on: {
            description: `Your next automatic payment is scheduled for {{date}}`,
            status: 'On',
            linkText: null,
          },
          off: {
            description: `Don't want to miss your payment? Turn on automatic payments by contacting our <linkComponent>support team!</linkComponent>`,
            status: 'Off',
            linkText: 'Turn on',
          },
          title: {
            paymentAccount: 'Payment account',
            paymentSchedule: 'Payment schedule',
            paymentScheduleEndingThisCycle: `Payment schedule ending this cycle`,
            paymentScheduleStartingNextCycle: `Payment schedule starting next cycle`,
          },
          linkText: {
            change: 'Change',
            delay: 'Delay',
          },
          paymentModes: {
            automaticPayment: 'Automatic Payment',
            billPayment: 'Bill Payment',
            manualPayment: 'Manual Payment',
            thirdPartyPayment: 'Third-Party Payment',
            disbursal: 'Disbursal',
            refund: 'Refund',
            interestWaiver: 'Interest Waiver',
            feeWaiver: 'Fee Waiver',
            loanCancellation: 'Loan Cancellation',
            unknown: 'Unknown',
          },
        },
        manualPayments: {
          title: `Make a manual payment`,
          description: `Choose a payment option`,
          buttonText: `Next`,
          paymentOptions: {
            outstandingAmount: {
              title: `Pay outstanding amount`,
              description: `Make a payment to avoid additional interest and fees`,
            },
            nextPayment: {
              title: `Pay my next payment`,
              description: `Pay your upcoming payment today`,
            },
            extraAmount: {
              title: `Pay extra amount`,
              description: `Pay off your loan faster by making extra payments`,
            },
            remainingBalance: {
              title: `Pay the remaining balance of my loan`,
              description: `Pay off your loan without any penalty`,
            },
          },
          infoMessage: {
            extraAmount: `Please note, if you are set up for automatic payment, your regular payment will still be processed as scheduled.`,
            nextPayment: `Your next payment scheduled on {{nextPaymentDate}} will be processed today.`,
          },
        },
        confirmPayment: {
          title: `Confirm payment`,
          buttonText: `Submit payment`,
          label: {
            paymentAmount: `Payment amount`,
            viewPaymentBreakdown: `View payment breakdown`,
            paymentDate: `Payment date`,
            transactionTime: `It may take up to 2 business days for transaction to show up in your bank account.`,
            paymentAccount: `Payment account`,
            consentCheckbox: `I understand my account will be debited for the selected amount within one business day.`,
            infoMessage: `Submitting additional payments does not reduce the amount of your regular scheduled payments. Instead, additional payments help you pay off your loan sooner, and pay less interest overall.`,
            principal: `Principal`,
            interest: `Interest`,
            creditCharges: `Credit charges`,
            paymentProtection: `Payment protection`,
            fees: `Fees`,
            totalAmount: `Total amount`,
          },
        },
        confirmNewPaymentMethod: {
          title: `Confirm new payment method`,
          description: `Are you sure you want to change your linked bank account to {{bankAccountName}}?`,
          buttonText: `Confirm`,
          label: {
            consentCheckbox: `I agree to the updated <linkComponent>PAD agreement</linkComponent>.`,
          },
        },
        accountAlreadyLinked: {
          title: `Your bank account is already linked.`,
          description: `It appears that this bank account has already been linked to your profile. `,
        },
        paymentSuccess: {
          title: `Payment Success!`,
          description: `Thank you. Your payment of {{amount}} has been completed.`,
          infoMessage: `It may take up to 2 business days for payment to process. Please make sure you have enough funds in your account during this period.`,
          buttonText: `Done`,
        },
        paymentDeferralSuccess: {
          title: `Your next payment is updated successfully!`,
          buttonText: `Done`,
        },
        errorModal: {
          title: `Oops, something's gone wrong`,
          description: `We’re working on fixing this as soon as possible. Click the ‘Try again’ button, or please reach out to us via <liveChatLink>Fig live chat</liveChatLink> or <1>email Fig support.</1>`,
          buttonText: `Try again`,
        },
        delayNextPayment: {
          title: `Delay next payment`,
          description: `Your next payment is currently scheduled for {{nextPaymentDate}}`,
          infoMessage: `Selecting a date past {{nextPaymentDate}} will incur a <underline>payment deferral</underline>, which is limited to once every 12 months. `,
          paymentDeferral: `Payment deferral`,
          datePicker: {
            label: `When would you like to make your next payment?`,
          },
          buttonText: `Next`,
        },
        confirmNewPaymentDate: {
          title: `Confirm new payment date`,
          description: `Your next payment of {{nextPaymentAmount}} will be processed on {{newPaymentDate}} from {{bankAccountName}}`,
          warningMessage: `Your selected date will incur a <underline>payment deferral</underline>, which is limited to once per 12 months`,
          infoMessage: `If your payment date falls on a weekend or holiday, it will be processed on the next business day.`,
          checkboxLabel: `I agree to the updated <linkComponent>payment deferral form</linkComponent>.`,
          paymentDeferral: `Payment deferral`,
          paymentsOverview: {
            title: `Next 3 payments overview`,
            finalPaymentLabel: `Final payment`,
          },
          buttonText: `Next`,
        },
      },
    },
  },
}
export default portalTranslation
