/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * NinjaAPI
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'

import type {
  BorrowerDetailSchema,
  CertificateOfInsuranceDownloadResponseSchema,
  ChangePaymentMethodGetPadAgreementRequestSchema,
  ChangePaymentMethodGetPadAgreementResponseSchema,
  ChangePaymentMethodLinkAccountRequestSchema,
  ChangePaymentMethodLinkBankAccountResponseSchema,
  DefaultSuccessResponseSchema,
  DisbursalHistoryResponseSchema,
  DocumentDownloadResponseSchema,
  DocumentHistoryResponseSchema,
  FlinksPaymentCheckRequestSchema,
  FlinksPaymentCheckResponseSchema,
  FlinksSetAccountRequestSchema,
  FlinksTrackEventRequestSchema,
  LoanQueryResponseSchema,
  MakePaymentRequestSchema,
  MakePaymentResponseSchema,
  ManualPaymentDetailResponseSchema,
  PaymentBreakdownRequestSchema,
  PaymentBreakdownResponseSchema,
  PaymentDeferralCompleteRequestSchema,
  PaymentDeferralCompleteResponseSchema,
  PaymentDeferralGetDeferralFormResponseSchema,
  PaymentDeferralProcessSelectedDateRequestSchema,
  PaymentDeferralProcessSelectedDateResponseSchema,
  PaymentDeferralValidationResponseSchema,
  PaymentDetailResponseSchema,
  PaymentScheduleChangeCompleteRequestSchema,
  PaymentScheduleChangeProcessSelectedDateRequestSchema,
  PaymentScheduleChangeProcessSelectedDateResponseSchema,
  PaymentScheduleChangeValidationResponseSchema,
  PaymentScheduleValidationRequestSchema,
  PrequalificationAffliateResponse,
  PrequalificationEmbeddedResponse,
  PrequalificationRequest,
  RepaymentScheduleResponseSchema,
  SuccessSchema,
  TransactionHistoryResponseSchema,
} from './api.schemas'

export const getNinjaAPI = () => {
  /**
 * Retrieve detailed information about a borrower based on the provided loan ID.

Args:
    request: The HTTP request object containing authentication details.
Returns:
    tuple: A tuple containing the HTTP status code and a dictionary with borrower details.
Raises:
    NinjaApiError: If there is an error parsing the Salesforce response.
The returned dictionary contains the following keys:
    - borrower_id (str): The ID of the borrower.
    - first_name (str): The first name of the borrower.
    - last_name (str): The last name of the borrower.
    - phone (str): The mobile phone number of the borrower.
    - email (str): The email address of the borrower.
    - complete_address (str): The complete address of the borrower.
    - billing_street (str): The billing street address of the borrower.
    - billing_city (str): The billing city of the borrower.
    - billing_state (str): The billing state of the borrower.
    - billing_country (str): The billing country of the borrower.
    - billing_postal_code (str): The billing postal code of the borrower.
    - language_preference (str): The language preference of the borrower.
 * @summary Get Borrower Detail
 */
  const portalApiGetBorrowerDetail = <TData = AxiosResponse<BorrowerDetailSchema>>(
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/borrower_detail`, options)
  }

  /**
 * Retrieves loan information for a given borrower from Salesforce by performing a SOQL query.

Args:
    request: The request object containing authentication and borrower information.

Returns:
    A tuple containing the HTTP status code and a dictionary with loan information.

Raises:
    NinjaApiError: If the borrower is not found, there is a loan query error, no record is found, or there is a parse error.
 * @summary Get Loan Detail
 */
  const portalApiGetLoanDetail = <TData = AxiosResponse<LoanQueryResponseSchema>>(
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan`, options)
  }

  /**
   * @summary Get Loan Repayment Schedule
   */
  const portalApiGetLoanRepaymentSchedule = <
    TData = AxiosResponse<RepaymentScheduleResponseSchema[]>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/repayment_schedule`, options)
  }

  /**
   * @summary Get Loan Transaction History
   */
  const portalApiGetLoanTransactionHistory = <
    TData = AxiosResponse<TransactionHistoryResponseSchema[]>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/transaction_history`, options)
  }

  /**
   * @summary Get Disbursal History
   */
  const portalApiGetDisbursalHistory = <TData = AxiosResponse<DisbursalHistoryResponseSchema[]>>(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/disbursal_history`, options)
  }

  /**
   * @summary Make Payment
   */
  const portalApiMakePayment = <TData = AxiosResponse<MakePaymentResponseSchema>>(
    loanId: string,
    makePaymentRequestSchema: MakePaymentRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/v1/portal/loan/${loanId}/make_payment`, makePaymentRequestSchema, options)
  }

  /**
   * @summary Get Document History
   */
  const portalApiGetDocumentHistory = <TData = AxiosResponse<DocumentHistoryResponseSchema[]>>(
    loanId: string,
    applicationId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/application/${applicationId}/documents`, options)
  }

  /**
   * @summary Download Document
   */
  const portalApiDownloadDocument = <TData = AxiosResponse<DocumentDownloadResponseSchema>>(
    loanId: string,
    documentId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/document/${documentId}/download`, options)
  }

  /**
   * @summary Get Payment Detail
   */
  const portalApiGetPaymentDetail = <TData = AxiosResponse<PaymentDetailResponseSchema[]>>(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/payment_detail`, options)
  }

  /**
   * @summary Get Manual Payment Detail
   */
  const portalApiGetManualPaymentDetail = <
    TData = AxiosResponse<ManualPaymentDetailResponseSchema>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/manual_payment_detail`, options)
  }

  /**
   * @summary Get Payment Breakdown
   */
  const portalApiGetPaymentBreakdown = <TData = AxiosResponse<PaymentBreakdownResponseSchema>>(
    loanId: string,
    paymentBreakdownRequestSchema: PaymentBreakdownRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/payment_breakdown`,
      paymentBreakdownRequestSchema,
      options,
    )
  }

  /**
   * @summary Flinks Set Account
   */
  const portalApiFlinksSetAccount = <TData = AxiosResponse<DefaultSuccessResponseSchema>>(
    flinksSetAccountRequestSchema: FlinksSetAccountRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/v1/portal/flinks/set-account`, flinksSetAccountRequestSchema, options)
  }

  /**
 * This endpoint is used to track flinks events to help debug issues. Silently fails as we don't want this to
block the user.
 * @summary Flinks Track
 */
  const portalApiFlinksTrack = <TData = AxiosResponse<DefaultSuccessResponseSchema>>(
    flinksTrackEventRequestSchema: FlinksTrackEventRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/v1/portal/flinks/track`, flinksTrackEventRequestSchema, options)
  }

  /**
 * This API is used for checking whether or not the applicant has successfully
saved and linked their flinks payment method.
 * @summary Flinks Payment Check
 */
  const portalApiFlinksPaymentCheck = <TData = AxiosResponse<FlinksPaymentCheckResponseSchema>>(
    flinksPaymentCheckRequestSchema: FlinksPaymentCheckRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/v1/portal/flinks/payment-check`, flinksPaymentCheckRequestSchema, options)
  }

  /**
   * @summary Change Payment Method Link Bank Account
   */
  const portalApiChangePaymentMethodLinkBankAccount = <
    TData = AxiosResponse<ChangePaymentMethodLinkBankAccountResponseSchema>,
  >(
    loanId: string,
    changePaymentMethodLinkAccountRequestSchema: ChangePaymentMethodLinkAccountRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/change_payment_method/link_bank_account`,
      changePaymentMethodLinkAccountRequestSchema,
      options,
    )
  }

  /**
   * @summary Change Payment Method Get Pad Agreement
   */
  const portalApiChangePaymentMethodGetPadAgreement = <
    TData = AxiosResponse<ChangePaymentMethodGetPadAgreementResponseSchema>,
  >(
    loanId: string,
    changePaymentMethodGetPadAgreementRequestSchema: ChangePaymentMethodGetPadAgreementRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/change_payment_method/get_pad_agreement`,
      changePaymentMethodGetPadAgreementRequestSchema,
      options,
    )
  }

  /**
   * @summary Payment Schedule Change Validate Schedule
   */
  const portalApiPaymentScheduleChangeValidateSchedule = <
    TData = AxiosResponse<PaymentScheduleChangeValidationResponseSchema>,
  >(
    loanId: string,
    paymentScheduleValidationRequestSchema: PaymentScheduleValidationRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/payment_schedule_change/validate_schedule`,
      paymentScheduleValidationRequestSchema,
      options,
    )
  }

  /**
   * @summary Payment Schedule Change Process Selected Date
   */
  const portalApiPaymentScheduleChangeProcessSelectedDate = <
    TData = AxiosResponse<PaymentScheduleChangeProcessSelectedDateResponseSchema>,
  >(
    loanId: string,
    paymentScheduleChangeProcessSelectedDateRequestSchema: PaymentScheduleChangeProcessSelectedDateRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/payment_schedule_change/process_selected_date`,
      paymentScheduleChangeProcessSelectedDateRequestSchema,
      options,
    )
  }

  /**
   * @summary Payment Schedule Change Complete
   */
  const portalApiPaymentScheduleChangeComplete = <TData = AxiosResponse<SuccessSchema>>(
    loanId: string,
    paymentScheduleChangeCompleteRequestSchema: PaymentScheduleChangeCompleteRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/payment_schedule_change/complete`,
      paymentScheduleChangeCompleteRequestSchema,
      options,
    )
  }

  /**
   * @summary Payment Deferral Validate Schedule
   */
  const portalApiPaymentDeferralValidateSchedule = <
    TData = AxiosResponse<PaymentDeferralValidationResponseSchema>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/payment_deferral/validate_schedule`,
      undefined,
      options,
    )
  }

  /**
   * @summary Payment Deferral Process Selected Date
   */
  const portalApiPaymentDeferralProcessSelectedDate = <
    TData = AxiosResponse<PaymentDeferralProcessSelectedDateResponseSchema>,
  >(
    loanId: string,
    paymentDeferralProcessSelectedDateRequestSchema: PaymentDeferralProcessSelectedDateRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/payment_deferral/process_selected_date`,
      paymentDeferralProcessSelectedDateRequestSchema,
      options,
    )
  }

  /**
   * @summary Payment Deferral Complete
   */
  const portalApiPaymentDeferralComplete = <
    TData = AxiosResponse<PaymentDeferralCompleteResponseSchema>,
  >(
    loanId: string,
    paymentDeferralCompleteRequestSchema: PaymentDeferralCompleteRequestSchema,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/payment_deferral/complete`,
      paymentDeferralCompleteRequestSchema,
      options,
    )
  }

  /**
   * @summary Payment Deferral Get Deferral Form
   */
  const portalApiPaymentDeferralGetDeferralForm = <
    TData = AxiosResponse<PaymentDeferralGetDeferralFormResponseSchema>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(
      `/v1/portal/loan/${loanId}/payment_deferral/get_deferral_form`,
      undefined,
      options,
    )
  }

  /**
   * @summary Payment Schedule Change Get Pad Agreement
   */
  const portalApiPaymentScheduleChangeGetPadAgreement = <
    TData = AxiosResponse<DocumentDownloadResponseSchema>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/payment_schedule_change/get_pad_agreement`, options)
  }

  /**
   * @summary Payment Protection Get Certificate Of Insurance
   */
  const portalApiPaymentProtectionGetCertificateOfInsurance = <
    TData = AxiosResponse<CertificateOfInsuranceDownloadResponseSchema>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(
      `/v1/portal/loan/${loanId}/payment_protection/get_certificate_of_insurance`,
      options,
    )
  }

  /**
   * @summary Create Prequalification Request
   */
  const personalLoansV2ApiCreatePrequalificationRequest = <
    TData = AxiosResponse<PrequalificationEmbeddedResponse>,
  >(
    prequalificationRequest: PrequalificationRequest,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/v1/personal_loans_v2/prequal`, prequalificationRequest, options)
  }

  /**
   * @summary Create Affliate Prequalification Request
   */
  const personalLoansV2ApiCreateAffliatePrequalificationRequest = <
    TData = AxiosResponse<PrequalificationAffliateResponse>,
  >(
    prequalificationRequest: PrequalificationRequest,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/v1/personal_loans_v2/e2e/prequal`, prequalificationRequest, options)
  }

  return {
    portalApiGetBorrowerDetail,
    portalApiGetLoanDetail,
    portalApiGetLoanRepaymentSchedule,
    portalApiGetLoanTransactionHistory,
    portalApiGetDisbursalHistory,
    portalApiMakePayment,
    portalApiGetDocumentHistory,
    portalApiDownloadDocument,
    portalApiGetPaymentDetail,
    portalApiGetManualPaymentDetail,
    portalApiGetPaymentBreakdown,
    portalApiFlinksSetAccount,
    portalApiFlinksTrack,
    portalApiFlinksPaymentCheck,
    portalApiChangePaymentMethodLinkBankAccount,
    portalApiChangePaymentMethodGetPadAgreement,
    portalApiPaymentScheduleChangeValidateSchedule,
    portalApiPaymentScheduleChangeProcessSelectedDate,
    portalApiPaymentScheduleChangeComplete,
    portalApiPaymentDeferralValidateSchedule,
    portalApiPaymentDeferralProcessSelectedDate,
    portalApiPaymentDeferralComplete,
    portalApiPaymentDeferralGetDeferralForm,
    portalApiPaymentScheduleChangeGetPadAgreement,
    portalApiPaymentProtectionGetCertificateOfInsurance,
    personalLoansV2ApiCreatePrequalificationRequest,
    personalLoansV2ApiCreateAffliatePrequalificationRequest,
  }
}
export type PortalApiGetBorrowerDetailResult = AxiosResponse<BorrowerDetailSchema>
export type PortalApiGetLoanDetailResult = AxiosResponse<LoanQueryResponseSchema>
export type PortalApiGetLoanRepaymentScheduleResult = AxiosResponse<
  RepaymentScheduleResponseSchema[]
>
export type PortalApiGetLoanTransactionHistoryResult = AxiosResponse<
  TransactionHistoryResponseSchema[]
>
export type PortalApiGetDisbursalHistoryResult = AxiosResponse<DisbursalHistoryResponseSchema[]>
export type PortalApiMakePaymentResult = AxiosResponse<MakePaymentResponseSchema>
export type PortalApiGetDocumentHistoryResult = AxiosResponse<DocumentHistoryResponseSchema[]>
export type PortalApiDownloadDocumentResult = AxiosResponse<DocumentDownloadResponseSchema>
export type PortalApiGetPaymentDetailResult = AxiosResponse<PaymentDetailResponseSchema[]>
export type PortalApiGetManualPaymentDetailResult = AxiosResponse<ManualPaymentDetailResponseSchema>
export type PortalApiGetPaymentBreakdownResult = AxiosResponse<PaymentBreakdownResponseSchema>
export type PortalApiFlinksSetAccountResult = AxiosResponse<DefaultSuccessResponseSchema>
export type PortalApiFlinksTrackResult = AxiosResponse<DefaultSuccessResponseSchema>
export type PortalApiFlinksPaymentCheckResult = AxiosResponse<FlinksPaymentCheckResponseSchema>
export type PortalApiChangePaymentMethodLinkBankAccountResult =
  AxiosResponse<ChangePaymentMethodLinkBankAccountResponseSchema>
export type PortalApiChangePaymentMethodGetPadAgreementResult =
  AxiosResponse<ChangePaymentMethodGetPadAgreementResponseSchema>
export type PortalApiPaymentScheduleChangeValidateScheduleResult =
  AxiosResponse<PaymentScheduleChangeValidationResponseSchema>
export type PortalApiPaymentScheduleChangeProcessSelectedDateResult =
  AxiosResponse<PaymentScheduleChangeProcessSelectedDateResponseSchema>
export type PortalApiPaymentScheduleChangeCompleteResult = AxiosResponse<SuccessSchema>
export type PortalApiPaymentDeferralValidateScheduleResult =
  AxiosResponse<PaymentDeferralValidationResponseSchema>
export type PortalApiPaymentDeferralProcessSelectedDateResult =
  AxiosResponse<PaymentDeferralProcessSelectedDateResponseSchema>
export type PortalApiPaymentDeferralCompleteResult =
  AxiosResponse<PaymentDeferralCompleteResponseSchema>
export type PortalApiPaymentDeferralGetDeferralFormResult =
  AxiosResponse<PaymentDeferralGetDeferralFormResponseSchema>
export type PortalApiPaymentScheduleChangeGetPadAgreementResult =
  AxiosResponse<DocumentDownloadResponseSchema>
export type PortalApiPaymentProtectionGetCertificateOfInsuranceResult =
  AxiosResponse<CertificateOfInsuranceDownloadResponseSchema>
export type PersonalLoansV2ApiCreatePrequalificationRequestResult =
  AxiosResponse<PrequalificationEmbeddedResponse>
export type PersonalLoansV2ApiCreateAffliatePrequalificationRequestResult =
  AxiosResponse<PrequalificationAffliateResponse>
