import { Grid2, Link, Modal } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FaqPage from 'src/components/PaymentProtection/FaqPage'
import { BoxModal } from 'src/components/PaymentProtection/styles'
import TermsPage from 'src/components/PaymentProtection/TermsPage'

export const ModalLinks = ({ isMobile }: { isMobile: boolean }) => {
  const { t } = useTranslation()
  const [openFaq, setOpenFaq] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)

  const toggleModal =
    (setter: React.Dispatch<React.SetStateAction<boolean>>): (() => void) =>
    () =>
      setter(prev => !prev)

  const modalLinks = [
    {
      open: openTerms,
      toggle: toggleModal(setOpenTerms),
      component: TermsPage,
      text: t('PaymentProtection.LinksText.termsLink'),
    },
    {
      open: openFaq,
      toggle: toggleModal(setOpenFaq),
      component: FaqPage,
      text: t('PaymentProtection.LinksText.faqLink'),
    },
  ]
  return (
    <Grid2 display="flex" marginTop="32px" justifyContent="space-around">
      {modalLinks.map(({ open, toggle, component: Component, text }) => (
        <>
          <Modal open={open} onClose={toggle}>
            <BoxModal isMobile={isMobile}>
              <Component handleClose={toggle} />
            </BoxModal>
          </Modal>
          <Link variant="linkMedium" onClick={toggle}>
            {text}
          </Link>
        </>
      ))}
    </Grid2>
  )
}
