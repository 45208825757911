import { Link, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { connectionSuccessImg, lightbulbIconBackground } from 'src/images'
import { PortalModalsEnum } from 'src/portal/common'
import { InfoActionRow } from 'src/portal/components/display/InfoActionRow'
import { ActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import { StatusIconEnum } from 'src/portal/components/types'
import { useLocale } from 'src/portal/hooks/useLocale'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'
import PortalTheme from 'src/themes/portal'
import { FRESHCHAT_CHANNEL_NAME } from 'src/utils/constants'

const AutomaticPaymentsDetailContent = () => {
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const { currencyFormat, dateFormatLocal } = useLocalizedFormatters()
  const { openModal } = useModalContext()
  const { isFrenchLocale } = useLocale()
  if (!loanDetailData) {
    return null
  }

  const automaticPaymentsOn = !loanDetailData.manual_pay

  const amountDue = currencyFormat(loanDetailData.due_amount)
  const paymentScheduleText = isFrenchLocale
    ? loanDetailData.payment_schedule_french.toLowerCase()
    : loanDetailData.payment_schedule.toLowerCase()

  const hasUpcomingRepaymentSchedule = !!loanDetailData.upcoming_repayment_schedule
  const upcomingRepaymentAmount = currencyFormat(loanDetailData?.upcoming_repayment_amount ?? 0)
  const upcomingRepaymentScheduleEnglish =
    loanDetailData?.upcoming_repayment_schedule?.split(' / ')[0] ?? ''
  const upcomingRepaymentScheduleFrench =
    loanDetailData?.upcoming_repayment_schedule?.split(' / ')[1] ?? ''
  const upcomingRepaymentSchedule = isFrenchLocale
    ? upcomingRepaymentScheduleFrench.toLowerCase()
    : upcomingRepaymentScheduleEnglish.toLowerCase()
  const rows = [
    {
      title: t(
        automaticPaymentsOn
          ? 'Portal.Home.label.summary.automaticPaymentsOn'
          : 'Portal.Home.label.summary.automaticPaymentsOff',
      ),
      content: (
        <StatusBadge
          iconVariant={automaticPaymentsOn ? StatusIconEnum.ACTIVE : StatusIconEnum.ALERT}
          label={t(
            automaticPaymentsOn
              ? 'Portal.Components.modal.automaticPayments.on.status'
              : 'Portal.Components.modal.automaticPayments.off.status',
          )}
        />
      ),
      linkText: null,
    },
    {
      title: t('Portal.Home.label.summary.upcomingPaymentDate'),
      content: (
        <Typography variant="body2" color={PortalTheme.color.grey7}>
          {dateFormatLocal(loanDetailData.due_date)}
        </Typography>
      ),
      linkText: t('Portal.Components.modal.automaticPayments.linkText.delay'),
      onClickHandler: () => openModal(PortalModalsEnum.DELAY_NEXT_PAYMENT_MODAL),
    },
    {
      title: t('Portal.Components.modal.automaticPayments.title.paymentAccount'),
      content: (
        <Typography variant="body2" color={PortalTheme.color.grey7}>
          {loanDetailData.payment_method}
        </Typography>
      ),
      linkText: t('Portal.Components.modal.automaticPayments.linkText.change'),
      onClickHandler: () => openModal(PortalModalsEnum.CHANGE_PAYMENT_METHOD_START),
    },
    ...(hasUpcomingRepaymentSchedule
      ? [
          {
            title: t(
              'Portal.Components.modal.automaticPayments.title.paymentScheduleEndingThisCycle',
            ),
            content: (
              <Typography variant="body2" color={PortalTheme.color.grey7}>
                {`${amountDue} ${paymentScheduleText}`}
              </Typography>
            ),
          },
          {
            title: t(
              'Portal.Components.modal.automaticPayments.title.paymentScheduleStartingNextCycle',
            ),
            content: (
              <Typography variant="body2" color={PortalTheme.color.grey7}>
                {`${upcomingRepaymentAmount} ${upcomingRepaymentSchedule}`}
              </Typography>
            ),
            linkText: t('Portal.Components.modal.automaticPayments.linkText.change'),
            onClickHandler: () => openModal(PortalModalsEnum.CHANGE_PAYMENT_RESCHEDULE_START),
          },
        ]
      : [
          {
            title: t('Portal.Components.modal.automaticPayments.title.paymentSchedule'),
            content: (
              <Typography variant="body2" color={PortalTheme.color.grey7}>
                {`${amountDue} ${paymentScheduleText}`}
              </Typography>
            ),
            linkText: t('Portal.Components.modal.automaticPayments.linkText.change'),
            onClickHandler: () => openModal(PortalModalsEnum.CHANGE_PAYMENT_RESCHEDULE_START),
          },
        ]),
  ]

  return (
    <>
      {rows.map(({ title, content, linkText, onClickHandler }) => (
        <InfoActionRow
          key={title}
          title={title}
          content={content}
          linkText={linkText}
          onClickHandler={onClickHandler}
        />
      ))}
    </>
  )
}

export const AutomaticPaymentsDetailModal = () => {
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const { dateFormatLocal } = useLocalizedFormatters()
  const { closeModal } = useModalContext()

  if (!loanDetailData) {
    return null
  }

  const upcomingPaymentDate = loanDetailData.due_date ?? ''
  const automaticPaymentsOn = !loanDetailData.manual_pay

  const date = dateFormatLocal(upcomingPaymentDate)
  const handleLinkClick: () => void = () => {
    closeModal()
    window.fcWidget?.open({ name: FRESHCHAT_CHANNEL_NAME })
  }

  const description = automaticPaymentsOn ? (
    t('Portal.Components.modal.automaticPayments.on.description', { date })
  ) : (
    <Trans
      i18nKey="Portal.Components.modal.automaticPayments.off.description"
      components={{
        linkComponent: (
          <Link
            onClick={handleLinkClick}
            sx={{ color: PortalTheme.color.brand1 }}
            color={PortalTheme.color.brand1}
            target="_blank"
            rel="noopener noreferrer"
          >
            support team
          </Link>
        ),
      }}
    />
  )

  const icon = automaticPaymentsOn ? connectionSuccessImg : lightbulbIconBackground

  return (
    <ActionModal
      icon={icon}
      iconAlt={t('Portal.Components.alt.checkmarkIcon')}
      description={description}
      content={<AutomaticPaymentsDetailContent />}
      closeButtonHandler={closeModal}
    />
  )
}
